<script>
  import { CepAPI } from '../http/cepapi'
  import { processFieldsErrors } from '../../utilities/componentErrorUtilities'

  const cepAPI = new CepAPI()

  export default {
    data: function(){
      return {
        instance: {
          name: "",
          code: "",
          company_name: "",
          cnpj: "",
          email: "",
          cep: "",
          city: "",
          address: "",
          district: "",
          state_id: "",
          phone_number_1: "",
          phone_number_2: "",
          avatar: "",
          default_user: {
            name: "",
            email: "",
          }
        },
        fieldErrors: {
          name: {
            hasError: false,
            message: "",
          },
          code: {
            hasError: false,
            message: "",
          },
          company_name: {
            hasError: false,
            message: "",
          },
          cnpj: {
            hasError: false,
            message: "",
          },
          email: {
            hasError: false,
            message: "",
          },
          cep: {
            hasError: false,
            message: "",
          },
          city: {
            hasError: false,
            message: "",
          },
          address: {
            hasError: false,
            message: "",
          },
          district: {
            hasError: false,
            message: "",
          },
          state_id: {
            hasError: false,
            message: "",
          },
          phone_number_1: {
            hasError: false,
            message: "",
          },
          phone_number_2: {
            hasError: false,
            message: "",
          },
          avatar: {
            hasError: false,
            message: "",
          },
          default_user: {
            name: {
              hasError: false,
              message: "",
            },
            email: {
              hasError: false,
              message: "",
            },
          }
        },
        stateOptions: [],
        disbleInputs: false
      }
    },
    props: {
      stateOptionsJson: {
        type: String,
        default: "[]"
      },
      initialData: {
        type: String,
        default: ""
      },
      fieldsWithError: {
        type: String,
        default: ""
      }
    },
    methods: {
      handleBlur: function(event){
        if(event.target.value.trim()!="" && event.target.value.length==9){
          this.disbleInputs=true
          cepAPI.getCEPData({
            cep: event.target.value,
            onResponse: (response) => {
                if(!response.data.erro){
                  this.instance.city = response.data.localidade
                  this.instance.address = response.data.logradouro
                  this.instance.district = response.data.bairro
                  this.instance.state_id = this.stateOptions.filter(state => state.initials.toLowerCase()==response.data.uf.toLowerCase())[0].value
                  this.disbleInputs=false
                  this.clearCEPErrors();
                }else{
                  this.disbleInputs=false
                  this.fieldErrors.cep.hasError=true
                  this.fieldErrors.cep.message="CEP Inválido"
                }
              },
            onError: (error)=>{
              this.fieldErrors.cep.message="Erro ao pesquisar CEP"
              this.fieldErrors.cep.hasError=true
              this.disbleInputs=false
            }
            });
        }else{
          this.disbleInputs=false
          this.clearCEPErrors()
          this.clearCEPInputs();
        }
      },
      inputName(key){
        return `core_laboratory[${key}]`
      },
      inputId(key){
        return `core_laboratory_${key}`
      },
      inputDefaultUserName(key){
        return `${this.inputName('default_user_attributes')}[${key}]`
      },
      inputDefaultUserId(key){
        return `${this.inputId('default_user_attributes')}_${key}`
      },
      clearCEPInputs(){
        this.instance.state_id=null
        this.instance.city=""
        this.instance.district=""
        this.instance.address=""
      },
      clearCEPErrors(){
        this.fieldErrors.cep=""
        this.fieldErrors.cep.hasError=false
      }
    },
    beforeMount(){
      this.stateOptions = JSON.parse(this.stateOptionsJson)
      this.instance = JSON.parse(this.initialData)
      processFieldsErrors(this.fieldErrors, JSON.parse(this.fieldsWithError))
    }
  }
</script>
