<script>
  import { CepAPI } from '../http/cepapi'
  import { processFieldsErrors, buildFieldsErrosStructure } from '../../utilities/componentErrorUtilities'

  const cepAPI = new CepAPI()

  export default {
    data: function(){
      return {
        instance: {
          name: "",
          code: "",
          laboratory_id: null,
          company_name: "",
          cnpj: "",
          email: "",
          cep: "",
          city: "",
          address: "",
          district: "",
          state_id: "",
          phone_number_1: "",
          phone_number_2: "",
          avatar: "",
          price_table_id: "",
          technical_manager: "",
          register: "",
          legal_responsible_name: "",
          legal_responsible_rg: "",
          legal_responsible_cpf: "",
          technical_manager_rg: "",
          technical_manager_cpf: "",
          report_contact_name: "",
          report_contact_email: "",
          attendance_contact_name: "",
          attendance_contact_email: "",
          technical_contact_name: "",
          technical_contact_email: "",
          billing_person_name: "",
          billing_phone_number: "",
          billing_email: "",
          billing_to: "",
          billing_comments: "",
        },
        fieldErrors: {},
        stateOptions: [],
        laboratoriesOptions: [],
        priceTablesOptions: [],
        billingToOptions: [],
        disbleInputs: false
      }
    },
    props: {
      stateOptionsJson: {
        type: String,
        default: "[]"
      },
      laboratoriesOptionsJson: {
        type: String,
        default: "[]"
      },
      initialData: {
        type: String,
        default: ""
      },
      pricesTables: {
        type: String,
        default: "[]"
      },
      fieldsWithError: {
        type: String,
        default: "[]"
      },
      billingToOptionsJson: {
        type: String,
        default: "[]"
      }
    },
    methods:{
      handleBlur: function(event){
        if(event.target.value.trim()!="" && event.target.value.length==9){
          this.disbleInputs=true
          cepAPI.getCEPData({
            cep: event.target.value,
            onResponse: (response) => {
                if(!response.data.erro){
                  this.instance.city = response.data.localidade
                  this.instance.address = response.data.logradouro
                  this.instance.district = response.data.bairro
                  this.instance.state_id = this.stateOptions.filter(state => state.initials.toLowerCase()==response.data.uf.toLowerCase())[0].value
                  this.disbleInputs=false
                  this.clearCEPErrors();
                }else{
                  this.disbleInputs=false
                  this.fieldErrors.cep.message="CEP Inválido"
                  this.fieldErrors.cep.hasError=true
                }
              },
            onError: (error)=>{
              this.fieldErrors.cep.message="Erro ao pesquisar CEP"
              this.fieldErrors.cep.hasError=true
              this.disbleInputs=false
            }
            });
        }else{
          this.disbleInputs=false
          this.clearCEPErrors()
          this.clearCEPInputs();
        }
      },
      inputName(attribute){
        return `core_clinic[${attribute}]`
      },
      inputId(attribute){
        return `core_clinic_${attribute}`
      },
      clearCEPInputs(){
        this.instance.state_id=null
        this.instance.city=""
        this.instance.district=""
        this.instance.address=""
      },
      clearCEPErrors(){
        this.fieldErrors.cep.hasError=false
      }
    },
    beforeMount(){
      this.fieldErrors = buildFieldsErrosStructure(
        [
          "name", 
          "code", 
          "laboratory_id", 
          "company_name", 
          "cnpj", 
          "email", 
          "cep", 
          "city", 
          "address", 
          "district", 
          "state", 
          "phone_number_1", 
          "phone_number_2", 
          "price_table_id",
          "price_table", 
          "technical_manager", 
          "register", 
          "support_email", 
          "legal_responsible_name", 
          "legal_responsible_rg", 
          "legal_responsible_cpf", 
          "technical_manager_rg", 
          "technical_manager_cpf", 
          "report_contact_name", 
          "report_contact_email", 
          "attendance_contact_name", 
          "attendance_contact_email", 
          "technical_contact_name", 
          "technical_contact_email", 
          "billing_person_name", 
          "billing_phone_number", 
          "billing_email", 
          "billing_to", 
          "billing_comments"
        ]
      )
      this.stateOptions = JSON.parse(this.stateOptionsJson)
      this.laboratoriesOptions = JSON.parse(this.laboratoriesOptionsJson)
      this.priceTablesOptions = JSON.parse(this.pricesTables)
      this.billingToOptions = JSON.parse(this.billingToOptionsJson)
      this.instance = JSON.parse(this.initialData)
      processFieldsErrors(this.fieldErrors, JSON.parse(this.fieldsWithError))
    }
  }
</script>