<template>
  <div>
    <h4>Documentos</h4>
    <div ui-row>
      <div ui-col col-xl="12" col-lg="12">
        <div>
          <div class="form-input">
            <div class="group-items">
              <div class="input-group">
                <vue-select-required-document-type 
                  :selected-document-types="selectedDocumentTypes"
                  :autocomplete-uri="autocompleteUri"
                  @select="handleSelect" @remove="handleDelete"
                >
                </vue-select-required-document-type>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <template v-for="(documentType, index) in selectedDocumentTypes">
        <div v-if="documentType._destroy!=1" ui-row :key="index" class="mt-3 card">
          <input type="hidden" :name="inputName(index, 'required_document_type_id')" v-model="documentType.required_document_type_id">
          <input type="hidden" :name="inputName(index, 'id')" v-model="documentType.id">
          <div ui-col col-xl="12" col-lg="12">
            <h5>{{ documentType.name }}</h5>
            <div ui-col col=2 flex-box left-center flex-gap=1>
              <label class="" :for="inputId(index, 'required')">Obrigatório</label>
              <vue-my-select
                :name="inputName(index, 'required')"
                :options="[{text: 'Sim', value: true}, {text: 'Não', value: false}]"
                v-model="documentType.required"
              ></vue-my-select>
            </div>
          </div>
        </div>
        <div v-else :key="index">
          <input type="hidden" :name="inputName(index, 'required_document_type_id')" v-model="documentType.required_document_type_id">
          <input type="hidden" :name="inputName(index, 'id')" v-model="documentType.id">
          <input :key="index" type="hidden" :name="inputName(index, '_destroy')" v-model="documentType._destroy">
        </div>
      </template>
    </div>
  </div>
</template>
<script>

import Multiselect from 'vue-multiselect'

export default {
  components:{
    Multiselect,
  },
  
  props: {
    initialData: {
      type: String,
      default: ''
    },
    autocompleteUri: {
      type : String,
      default : () => ""
    },
    nestedIndex: {
      type : Number,
      default : () => null
    }
  },
 
  data: function(){
    return {
      selectedDocumentTypes : [],
      index : null
    }
  },
  
  computed: {
    hasSelectedDocumentTypes: function(){
      return this.selectedDocumentTypes.length > 0
    }
  },
  
  methods: {
    inputName: function(id, attribute){
      return `core_exam[exam_laboratories_attributes][${this.index}][exam_laboratory_required_document_types_attributes][${id}][${attribute}]`
    },
    
    inputId: function(id, attribute){
      return `core_exam_exam_laboratories_attributes_${this.index}_exam_laboratory_required_document_types_attributes_${id}_${attribute}`
    },

    inputDocumentTypeId: function(id, attribute){
      return `core_exam_exam_laboratories_attributes_${this.index}_exam_laboratory_required_document_types_attributes${id}_${attribute}`
    },
    
    handleSelect: function(selectedDocumentType){
      let exists = this.selectedDocumentTypes.filter(item=>item.id == selectedDocumentType.id).length>0
      if(!exists){
        this.selectedDocumentTypes.push({
          required_document_type_id: selectedDocumentType.id,
          name: selectedDocumentType.name,
          required: selectedDocumentType.required
        });
      }
    },
    
    handleDelete: function(deleteDocumentType) {
      this.selectedDocumentTypes = this.selectedDocumentTypes.map(item => {
        if(!!item.id && item.id == deleteDocumentType.id) {
          item={...item, _destroy: 1}
        }
        return item
      }).filter(item => !!item.id || item.required_document_type_id != deleteDocumentType.required_document_type.id)
    },

    handleDisplayTitle(option) {
      return option.name
    }
  },
  
  beforeMount(){
    JSON.parse(this.initialData).map(item => {
      this.selectedDocumentTypes.push(item)
    });
    this.index = this.nestedIndex;
  }
}
</script>
