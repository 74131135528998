<template>
  <form :action="action" method="get" ref="filters_form" @submit="submitHandler">
    <div class="row mb-3">
      <div class="col-5">
        <div class="row mt-0">
          <div class="col-7">
            <div class="input-group">
              <input 
                type="text" 
                class="form-control input-filter" 
                background="bi bi-search" 
                name="term" 
                aria-label="Username"
                id="term_search"
                placeholder="Pesquise por código, nome, exame e paciente"
                aria-describedby="basic-addon1" 
                @keypress="handleSearch" 
                v-model="term"
              >
            </div>
          </div>
          <div class="col-5">
            <div class="input-group">
              <select id="status_search" name="status" class="form-control input-filter">
                <option value="" disabled selected>Status</option>
                <template v-for="(status, key) of statusesList">
                  <option v-if="status.value==currentStatus" selected :key="key" :value="status.value">{{ status.name }}</option>
                  <option v-else :value="status.value" :key="key">{{ status.name }}</option>
                </template>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-7">
        <div class="row mt-0">
          <div class="col-3">
            <div class="input-group">
              <vue-my-datepicker placeholder="Data do Pedido" name="date_order" id="date_order" v-model="dateOrder" input-class="form-control input-filter"/>
            </div>
          </div>
          <div class="col-3">
            <div class="input-group">
              <vue-my-datepicker placeholder="Data Inicial" name="date_begin" id="date_begin" v-model="dateBegin" input-class="form-control input-filter"/>
            </div>
            <span class="font-red">{{ dateBeginError }}</span>
          </div>
          <div class="col-3">
            <div class="input-group">
              <vue-my-datepicker placeholder="Data Final" name="date_end" id="date_end" v-model="dateEnd" input-class="form-control input-filter"/>
            </div>
            <span class="font-red">{{ dateEndError }}</span>
          </div>
          <div class="col">
            <button class="btn btn-primary filter-btn" style="margin-top:0.5em">
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    action: String,
    initialData: String,
    statuses: String,
    currentStatus: String,
  },
  data: function(){
    return {
      dateOrder: null,
      dateBegin: null,
      dateBeginError: "",
      dateEnd: null,
      dateEndError:"",
      term: "",
      status: "",
      statusesList: []
    }
  },
  methods: {
    handleSearch: function(event){
      if(event.which==13){
        this.$refs.filters_form.submit()
      }
    },
    submitHandler: function(event){
      if(!this.validate()){
        event.preventDefault();
      }
    },
    validate: function(event){
      if(this.dateBegin.getTime()>this.dateEnd.getTime()){
        this.dateBeginError="Data Inválida"
        this.dateEndError = "Data Inválida"
        return false
      }
      this.dateBeginError="";
      this.dateEndError="";
      return true
    }
  },
  beforeMount(){
    const data = JSON.parse(this.initialData)
    this.term = data.term
    this.dateOrder = !!data.date_order ? new Date(data.date_order) : null
    this.dateBegin = !!data.date_begin ? new Date(data.date_begin) : null
    this.dateEnd = !!data.date_end? new Date(data.date_end) : null
    this.statusesList = JSON.parse(this.statuses)
  }
}
</script>
