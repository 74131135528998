<template>
  <multiselect
    v-model="localSelectedExams"
    id="ajax" 
    label="autocomplete_name" 
    track-by="id"
    placeholder="Digite para pesquisar" 
    open-direction="bottom" 
    :options="exams"
    :multiple="true"
    :searchable="true" 
    :showNoResults="false"
    :loading="isLoading" 
    :internal-search="false" 
    :close-on-select="true" 
    :options-limit="300" 
    :limit="3" 
    :max-height="600" 
    :show-no-results="false" 
    @search-change="asyncFind"
    @select="handleSelect"
    @remove="handleRemove"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <span class="multiselect__tag">
          <span>{{option.code}}</span> 
          <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
        </span>
      </template>
      <template slot="clear" slot-scope="props">
        <div class="multiselect__clear" v-if="hasSelectedExams" @mousedown.prevent.stop="clearAllData(props.search)"></div>
      </template>
      <span slot="noResult">Nenhum exame encontrado com este termo.</span>
      <span slot="noOptions">Nenhum exame disponível. Digite para pesquisar.</span>
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { GlobalAutocomplete } from '../http/autocomplete'

const globalAutocomplete = new GlobalAutocomplete()

export default {
  components:{
    Multiselect
  },
  props: {
    selectedExams: {
      type: Array,
      default: []
    }
  },
  data: function (){
    return {
      isLoading: false,
      exams: [],
      localSelectedExams: []
    }
  },
  computed: {
    hasSelectedExams: function(){
      return this.localSelectedExams.length>0
    }
  },
  methods: {
    asyncFind (query) {
      this.isLoading = true
      globalAutocomplete.autocomplete('exam_laboratories',query).then(response => {
        this.exams = response.data.exams
        this.isLoading = false
      })
    },
    handleSelect(selectOption){
      this.$emit('select', selectOption)
    },
    handleRemove(removedOption){
      this.$emit('remove', removedOption)
    }
  },
  beforeMount(){
    this.localSelectedExams = [...this.selectedExams]
  }
}
</script>
