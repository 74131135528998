<script>
  import { CepAPI } from '../http/cepapi'
  import { processFieldsErrors, buildFieldsErrosStructure } from '../../utilities/componentErrorUtilities'

  const cepAPI = new CepAPI()

  export default {
    data: function(){
      return {
        instance: {
          name: "",
          company_name: "",
          laboratory_id: null,
          clinic_id: null,
          cnpj: "",
          cep: "",
          state: "",
          city: "",
          address: "",
          district: "",
          uf: "",
          email: "",
          phone_number_1: "",
          phone_number_2: "",
          support_email  : "",
          technical_manager: "",
          technical_manager_rg: "",
          technical_manager_cpf: "",
          report_contact_name: "",
          report_contact_email: "",
          attendance_contact_name: "",
          attendance_contact_email: "",
          technical_contact_name: "",
          technical_contact_email: "",
        },
        fieldErrors: {},
        stateOptions: [],
        disbleInputs: false
      }
    },
    props: {
      stateOptionsJson: {
        type: String,
        default: "[]"
      },
      initialData: {
        type: String,
        default: "{}"
      },
      fieldsWithError: {
        type: String,
        default: "[]"
      },
    },
    methods:{
      handleBlur: function(event){
        if(event.target.value.trim()!="" && event.target.value.length==9){
          this.disbleInputs=true
          cepAPI.getCEPData({
            cep: event.target.value,
            onResponse: (response) => {
                if(!response.data.erro){
                  this.instance.city = response.data.localidade
                  this.instance.address = response.data.logradouro
                  this.instance.district = response.data.bairro
                  this.instance.state_id = this.stateOptions.filter(state => state.initials.toLowerCase()==response.data.uf.toLowerCase())[0].value
                  this.disbleInputs=false
                  this.clearCEPErrors();
                }else{
                  this.disbleInputs=false
                  this.fieldErrors.cep.message="CEP Inválido"
                  this.fieldErrors.cep.hasError=true
                }
              },
            onError: (error)=>{
              this.fieldErrors.cep.message="Erro ao pesquisar CEP"
              this.fieldErrors.cep.hasError=true
              this.disbleInputs=false
            }
            });
        }else{
          this.disbleInputs=false
          this.clearCEPErrors()
          this.clearCEPInputs();
        }
      },
      inputName(attribute){
        return `core_clinic_unity[${attribute}]`
      },
      inputId(attribute){
        return `core_clinic_unity${attribute}`
      },
      clearCEPInputs(){
        this.instance.state_id=null
        this.instance.city=""
        this.instance.district=""
        this.instance.address=""
      },
      clearCEPErrors(){
        this.fieldErrors.cep.hasError=false
      }
    },
    beforeMount(){
      this.fieldErrors = buildFieldsErrosStructure(
        [
          "laboratory_id",
          "clinic_id",
          "name",
          "company_name",
          "cnpj",
          "cep",
          "city",
          "address",
          "district",
          "uf",
          "state",
          "email",
          "phone_number_1",
          "phone_number_2",
          "support_email",
          "technical_manager",
          "technical_manager_rg",
          "technical_manager_cpf",
          "report_contact_name",
          "report_contact_email",
          "attendance_contact_name",
          "attendance_contact_email",
          "technical_contact_name",
          "technical_contact_email",
          "laboratory_id"
        ]
      )
      this.stateOptions = JSON.parse(this.stateOptionsJson)
      this.instance = JSON.parse(this.initialData)
      processFieldsErrors(this.fieldErrors, JSON.parse(this.fieldsWithError))
    }
  }
</script>