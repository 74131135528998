<template>
  <div>
    <label class="form-label" for="ajax">Exames</label>
    <multiselect 
      v-model="value" 
      :options="selectedExams" 
      :multiple="true" 
      :close-on-select="true" 
      :clear-on-select="true" 
      :preserve-search="true"
      :hideSelected="true"
      placeholder="Digite o código do exame" 
      label="autocomplete_name"
      track-by="id"
      :preselect-first="false">
        <template slot="tag" slot-scope="{ option, remove }">
          <span class="multiselect__tag">
            <span>{{option.code}}</span> 
            <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
          </span>
        </template>
        <span slot="noResult">Nenhum exame encontrado com este termo.</span>
        <span slot="noOptions">Volte ao passo "Exames" e selecione os exames desejados</span>
    </multiselect>
    <div v-for="(item, examsIndex) in value" :key="examsIndex">
      <input type="hidden" v-bind:value="item.deadline" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][order_exam_laboratories_attributes][${examsIndex}][deadline]`"/>
      <input type="hidden" v-bind:value="item.exam_id" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][order_exam_laboratories_attributes][${examsIndex}][exam_id]`"/>
      <input type="hidden" v-bind:value="item.id" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][order_exam_laboratories_attributes][${examsIndex}][exam_laboratory_id]`"/>
      <input type="hidden" v-bind:value="item.price" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][order_exam_laboratories_attributes][${examsIndex}][price]`"/>
      <input type="hidden" v-bind:value="item.laboratory_id" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][order_exam_laboratories_attributes][${examsIndex}][laboratory_id]`"/>
      <input type="hidden" v-bind:value="item.code" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][order_exam_laboratories_attributes][${examsIndex}][exam_code]`"/>
      <input type="hidden" v-bind:value="item.name" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][order_exam_laboratories_attributes][${examsIndex}][exam_name]`"/>
      <input type="hidden" v-bind:value="item.external_exam_name" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][order_exam_laboratories_attributes][${examsIndex}][external_exam_name]`"/>
    </div>
  </div>
</template>

<script>

  import Multiselect from 'vue-multiselect'
  import { mapState, mapActions } from 'vuex';

  import { ExamsApi } from '../http/examsApi'
  const examsApi = new ExamsApi()

  const storeModuleName = 'attendanceFormModule';

  export default {
    components: {
        Multiselect
    },
    props: {
      index: null,
      exams: {
        type: Array,
        default : () => []
      }
    },
    data () {
      return {
        value: this.exams,
        isLoading: false
      }
    },
    computed: {
      ...mapState(storeModuleName, ['selectedExams'])
    },
    methods : {
      clear() {
        this.clearAll();
      },

      getDeliveryDate(deadline) {
        let date = new Date();
        date.setDate(date.getDate() + deadline)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let fullDay = day.toString().length == 1 ? '0'+day : day
        let fullMonth = month.toString().length == 1 ? '0'+month : month
        return year + "-" + fullMonth + "-" + fullDay
      },
      ...mapActions(storeModuleName, ['clearAll', 'resetOrderExams', ])
    }
  }
  
</script>