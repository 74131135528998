<script>
export default {
    data(){
        return {
            resumeHTML: '',
        }
    },
    methods:{
        onResumeActive(){
            const formData = new FormData(document.getElementById('new_order_attendance'));
            this.$apiService.getResume({
                data: formData,
                onSucess: (response)=>{
                    this.resumeHTML = response.data;
                },
                onError: (response)=>{
                }
            })
        }
    }
}
</script>