<script>
  export default {
    props:{
      overflow: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default:'Modal Title'
      },
      icon: {
        type: String,
        default: 'bi bi-file-earmark-text'
      }
    },
    data () {
      return {
          cleanModal: false,
          showModal: false,
          loadingSubmit: false,
      }
      },
    watch: {
      showModal: {
        immediate: true,
        deep: true,
        handler(newValue, oldValue) {
            if (newValue) {
                document.body.classList.add('no-scroll');
            }
            if (!newValue) {
                document.body.classList.remove('no-scroll');
            }
        }
      }
    },
    computed:{
      bodyStyle: function(){
        var _style = {};

        if(this.overflow == true){
          _style.overflow = 'scroll';
        }
        return _style
      }
    },
    methods: {
      close() {
        this.showModal = false;
      },
      open(){
        this.showModal = true;
      }
    },
    created() {
      const that = this;
      document.addEventListener('keyup', function (evt) {
        if (evt.keyCode === 27) {
          that.close();
        }
      });
    }
  }
</script>

<template>
  <div class="modal-component" :showmodal="showModal">
    <div bg-modal></div>
    <div class="modal-box" ref="modal-page">
      <div header>
        <div class="d-flex justify-content-center">
            <i :class="icon"></i>
            <div class="modal-title">{{ title }}</div>
        </div>
        <span class="icon-close" @click="close">
          <i class="bi bi-x-lg"></i>
        </span>
      </div>
      <div body :style="bodyStyle">
        <slot name="body"></slot>
      </div>
      <div class="wrap-btn-modal" flex-box flex-wrap flex-gap=1>
        <slot name="buttons" ></slot>
      </div>
    </div>
  </div>
</template>