<template>
    <div id="materials-step">
        <div class="row" mb=1>
            <h4 class="font-primary" mb=1> Exames adicionados ao seu pedido </h4>
            <div id="exams-list" pb=1>
                <div v-for="(exam, index) in selectedExams" :key="index">
                    <div class="row exam-row" :class="examRowClass(index)">
                        <span> {{ exam.name }} ({{exam.code}}) </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div v-if="items.length > 0" class="col-12">
                <div class="card" v-for="(value, index) in items" :key="index" mb=1>
                    <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <h4 mb=0>Amostra {{ index + 1 }}</h4>
                                <a class="font-red" style="margin-top:0.2em; cursor: pointer" title="Remover" @click="handleRemoveSample(index)"><i class="bi bi-trash"></i></a>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <div w-100 :class="value.errors.exams_laboratories.hasError ? 'field_with_errors' : ''">
                                        <vue-selects-select-attendance-exams :index=index :exams="value.exams_laboratories"></vue-selects-select-attendance-exams>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div w-100 :class="value.errors.quantity.hasError ? 'field_with_errors' : ''">
                                        <vue-sample-quantity :index=index :quantity="formatNumberData(value.quantity, 1)"></vue-sample-quantity>
                                    </div>
                                </div>
                            </div>
                            <div class="row" mt=1>
                                <div class="col-sm">
                                    <div w-100 :class="value.errors.material.hasError ? 'field_with_errors' : ''">
                                        <vue-selects-select-materials :initial-options="initialOptionsMaterials" :index=index :selected="formatObjectData(value.material)"></vue-selects-select-materials>
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <div w-100 :class="value.errors.recipient.hasError ? 'field_with_errors' : ''">
                                        <vue-selects-select-recipients :initial-options="initialOptionsRecipients" :index=index :selected="formatObjectData(value.recipient)"></vue-selects-select-recipients>
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <div w-100>
                                        <label class="form-label">Local da Coleta</label>
                                        <div class="form-input">
                                            <div class="group-items">
                                                <div class="input-group">
                                                    <vue-my-select 
                                                        :options="collectPlaceOptions"
                                                        :name="`order_attendance[order_exam_orders_attributes][${index}][collect_place]`" > </vue-my-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div w-100 :class="value.errors.collection_date.hasError ? 'field_with_errors' : ''">
                                        <vue-sample-dates :index=index :collectionDate="value.collection_date"></vue-sample-dates>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div v-else class="empty-step-container">
                    <h1>Nenhuma amostra adicionada </h1>
            </div>
        </div>
        <div class="row">
            <button class="col-3 button-outline" mt=1 v-on:click.prevent="addSample()">Adicionar amostra</button>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'

import componentSelectAttendanceExams from './selects/selectAttendanceExams'
import componentSelectMaterials from './selects/selectMaterials'
import componentSelectRecipients from './selects/selectRecipients'
import { processFieldsErrors } from '../../utilities/componentErrorUtilities'



export default {
    components: {
        componentSelectAttendanceExams, componentSelectMaterials, componentSelectRecipients
    },
    computed: {
        ...mapState('attendanceFormModule', ['selectedExams','examsIds'])
    },
    props: {
        samples: {
            type : Array,
            default: () => []
        },
        selectedMaterial : {
            type : Object,
            default: () => {} 
        },
        selectedRecipient : {
            type : Object,
            default: () => {} 
        },
        initialOptionsMaterials : {
            type : Array,
            default: () => []
        },
        initialOptionsRecipients : {
            type : Array,
            default: () => []
        },
        collectPlaceOptions: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            items : [],
            material : this.selectedMaterial,
            recipient : this.selectedRecipient
        }
    },
    methods : {
        addSample() {
            let sampleError = {
                exams_laboratories: {
                    hasError: false,
                    message: "",
                },
                material: {
                    hasError: false,
                    message: "",
                },
                recipient: {
                    hasError: false,
                    message: "",
                },
                quantity: {
                    hasError: false,
                    message: "",
                },
                collection_date: {
                    hasError: false,
                    message: ""
                }
            }
            this.items.push({"exam_ids" : [], "errors": sampleError})
        },

        formatArrayData(data) {
            if(data !=  null) 
                return JSON.parse(data)
            return []
        },

        formatObjectData(data) {
            if(data !=  null) 
                return JSON.parse(data)
            return {}
        },

        formatSelectedData(data) {
            if(data != null)
                return JSON.parse(data)
            return null
        },

        formatNumberData(data, defaultValue) {
            if(data != null)
                return parseInt(data)
            return defaultValue
        },
        examRowClass(index){
            return {
                'odd': (index%2 == 0),
            }
        },
        handleRemoveSample(indexToRemove){
            this.items=this.items.filter((item, index) => index!=indexToRemove)
        }
        
    },
    beforeMount(){
        if(this.samples.length>0){
            this.samples.forEach(sample => {
                let sampleError = {
                    exams_laboratories: {
                        hasError: false,
                        message: "",
                    },
                    material: {
                        hasError: false,
                        message: "",
                    },
                    recipient: {
                        hasError: false,
                        message: "",
                    },
                    quantity: {
                        hasError: false,
                        message: "",
                    },
                    collection_date: {
                        hasError: false,
                        message: ""
                    }
                }
                processFieldsErrors(sampleError, sample.errors)
                sample.errors=sampleError;
            });
            this.items = this.samples;
        }else{
            this.addSample();
        }
    },
}
</script>
