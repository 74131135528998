<template>
    <div id="documents-step" mt=1>
        <h6> Os documentos devem conter uma imagem legível e a assinatura do médico. Ela será avaliada pelo time de atendimento para confirmação e validação.</h6>
        <div v-if="hasDocuments">
            <div v-for="(document, index) in documents" :key="document.exam_name" class="card" mb=1>
                <div class="row">
                    <h4 class="col-12 card-title">{{ document.exam_name }}</h4>
                </div>
                <div class="row" v-if="document.required_documents.length > 0">
                    <div>
                        <div class="col-12" v-for="(required_document, required_document_index) in document.required_documents" :key="required_document.id" ml=1>
                            <div flex-box flex-column-direction mb=1>
                                <span mb=0.5>{{ required_document.name }}</span>
                                <h6 v-if="required_document.mandatory" mb=0.5>Não será aceito o exame sem esse documento</h6>
                                <div flex-box left-center flex-gap=3>
                                    <div class="file-input" flex-box flex-space="between">
                                        <span class="file-name" :ref="inputId(index + required_document_index)"></span>
                                        <label :for="inputId(index + required_document_index)" class="bi bi-paperclip button" mb=0>Anexar Arquivo</label>
                                    </div>
                                    <input type="file"
                                        hidden="true"
                                        v-bind:id="inputId(index + required_document_index)"
                                        v-bind:name="inputName(index + required_document_index)"
                                        @change="onFileInput($event, document)">
                                    <input type="hidden" 
                                        v-bind:id="inputDocumentTypeId(index + required_document_index)"
                                        v-bind:name="inputDocumentTypeName(index + required_document_index)"
                                        v-bind:value="required_document.id">
                                    <div flex-box left-center flex-gap=1 v-if="hasModelFile(required_document.model_file)" >
                                        Modelo: <vue-single-file-input
                                            :file=parseFileData(required_document.model_file)>
                                        </vue-single-file-input>
                                    </div>
                                </div>
                            </div>
                            <input type="hidden"
                                v-bind:id="fieldDocumentIdInputId(index + required_document_index)"
                                v-bind:name="fieldDocumentIdInputName(index + required_document_index)"
                                v-bind:value="required_document.document_id"
                            >
                        </div>
                    </div>
                </div>
                <div v-else class="row" mt="0">
                    <h5> Sem documentos obrigatórios </h5>
                </div>
            </div>
        </div>
        <div v-else class="empty-step-container">
            <h1> Sem documentos obrigatórios </h1>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapState, mapActions } from 'vuex'
const storeModuleName = 'attendanceFormModule';

export default{
    props:{
        initialDocuments: {
            type: String,
            default: "[]",
        }
    },
    computed: {
        ...mapState(storeModuleName, ['documents']),
        ...mapGetters(storeModuleName, ['hasDocuments'])
    },
    methods: {
        onFileInput(event, document){
            var _refElement = this.$refs[event.target.id][0];
            _refElement.textContent = event.target.files[0].name;
            document = {... document, file_name: event.target.files[0].name};
        },
        inputId(index){
            return `order_attendance_documents_attributes_${index}_file`;
        },
        inputName(index){
            return `order_attendance[documents_attributes][${index}][file]`;
        },
        inputDocumentTypeId(index){
            return `order_attendance_documents_attributes_${index}_required_document_type_id`;
        },
        inputDocumentTypeName(index) {
            return `order_attendance[documents_attributes][${index}][required_document_type_id]`;
        },
        fieldDocumentIdInputId(index){
            return `order_attendance_documents_attributes_${index}_id`;
        },
        fieldDocumentIdInputName(index){
            return `order_attendance[documents_attributes][${index}][id]`;
        },
        parseFileData(fileData) {
            return JSON.parse(fileData);
        },
        hasModelFile(fileData) {
            var parsedFileData = this.parseFileData(fileData);
            return parsedFileData.path != null;
        },
        ...mapActions(storeModuleName, ['setDocuments'])
    },
    beforeMount(){
        let documents = JSON.parse(this.initialDocuments)
        documents.forEach((document)=>{
            this.setDocuments(document)
        })
    }
}
</script>
