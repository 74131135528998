<template>
  <div>
      <input type="hidden" name="order_attendance[company_unity_id]" :value="companyUnity.id">
      <label class="form-label" for="ajax">Escolha a unidade onde será feita a solicitação*</label>
      <multiselect
        id="ajax" 
        label="name" 
        track-by="name"
        placeholder="Digite para pesquisar"
        open-direction="bottom" 
        :options="unities"
        :value="companyUnity"
        :multiple="false"
        :searchable="true" 
        :hideSelected="true"
        :showNoResults="false"
        :loading="isLoading" 
        :internal-search="false"
        :clear-on-select="true"
        :taggable="false" 
        :close-on-select="true" 
        :options-limit="300" 
        :limit="3" 
        :max-height="600" 
        :show-no-results="false"
        :hide-selected="true" 
        @search-change="asyncFind"
        @select="selectHandle"
      >
          <template slot="tag" slot-scope="{ option, remove }">
              <span class="custom__tag">
              <span>{{ option.name }}</span>
              <span class="custom__remove" @click="remove(option)">❌</span>
              </span>
          </template>
          <span slot="noResult">Nenhum registro encontrado com este termo.</span>
          <span slot="noOptions">Nenhum registro disponível. Digite para pesquisar.</span>
      </multiselect>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import Multiselect from 'vue-multiselect'

  import { GlobalAutocomplete } from '../../http/autocomplete'
  const globalAutocomplete = new GlobalAutocomplete()
  const storeModuleName = 'attendanceFormModule';

  export default{
    components: {
      Multiselect
    },
    props: {
      selected: {
        type: Object,
        default: () => {}  
      }
    },
    data: function(){
      return {
        isLoading: false,
        unities: []
      }
    },
    computed: {
      ...mapGetters(storeModuleName, ['companyUnity'])
    },
    methods: {
      asyncFind (query) {
        if(query.length >= 3) {
          this.isLoading = true
          globalAutocomplete.autocomplete('company_unities',query).then(response => {
            this.unities = response.data.unities
            this.isLoading = false
          })
        }
      },
      selectHandle(data){
        this.setCompanyUnity(data)
      },
      ...mapActions(storeModuleName, ['setCompanyUnity'])
    },
    beforeMount(){
      this.setCompanyUnity(this.selected)
    }
  }
</script>
