
import axios from 'axios'

export class GlobalAutocomplete {
  constructor(){
    axios.defaults.headers = {'Content-Type': 'application/json'}
    this.http = axios;
  }

  autocomplete(endpointName, searchQuery, permitNew=true){
    var _url = '/admin/autocomplete/'+endpointName+'/?term='+searchQuery+'&permitNew='+permitNew;
    return this.http({
      url: _url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    }).catch(e => {
      return e.response;
    });
  }

  autocompleteWithEndpoint(endpointName, searchQuery){
    var _url = endpointName+'?term='+searchQuery;
    return this.http({
      url: _url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    }).catch(e => {
      return e.response;
    });
  }
}