export function buildFieldsErrosStructure(fields){
  const base = {
    hasError: false,
    message: ""
  }
  let struct = {};
  for(let field of fields){
    struct[field] = {...base}
  }
  return struct
}

export function processFieldsErrors(errorFields, fields){
  fields.forEach(field=> {
    let split = field.split(".")
    let errorField = errorFields[split[0]]
    split.shift()
    for(let key of split){
      if(errorField[key] !== undefined){
        errorField = errorField[key];
      }
    }
    errorField.hasError = true;
  })
}