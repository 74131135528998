<template>
		<div id="patient-step" >
			<div>
				<div class="row">
					<div class="col-6">
                        <label class="form-label"> Nome </label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
                                    <template v-if="!patientEdit">
                                        <div w-100 :class="patientErrors.name.hasError ? 'field_with_errors' : ''">
                                            <vue-my-select
                                                v-model=selectedPatientId
                                                :name="'order_attendance[patient_id]'"
                                                :options="mapPatientOptions"
                                                :placeholder="'Pesquise pelo Nome, E-mail ou Número do Documento'"
                                                :searchable="true"
                                                :close-on-select="true"
                                                :async-find="findPatient"
                                                :disable-input="!disableInputs"
                                                :icon="'search'"
                                            >
                                                <template slot="singleLabel" slot-scope="{ option }">
                                                    {{option.name}}
                                                </template>
                                                <template slot="noResult">
                                                    Nenhum paciente encontrado
                                                </template>
                                            </vue-my-select>
                                        </div>
                                        <input v-if="!disableInputs" type="hidden" :name="inputName('name')" v-model="patient.name">
                                    </template>
                                    <template v-else>
                                        <input type="hidden" :name="inputName('id')" v-model="patient.id">
                                        <input type="text" :name="inputName('name')" v-model="patient.name">
                                    </template>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3">
                        <label class="form-label"> Tipo do documento </label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
                                    <div w-100 :class="patientErrors.document_type.hasError ? 'field_with_errors' : ''">
                                        <vue-my-select
                                            v-model="patient.document_type"
                                            :name="inputName('document_type')"
                                            :options="documentOptions"
                                            :value="patient.document_type"
                                            :disabled="disableInputs"
                                            :placeholder="'Selecione o tipo do documento'"
                                        />
                                    </div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3">
                        <label class="form-label"> Número do documento </label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
                                    <div w-100 :class="patientErrors.document_number.hasError ? 'field_with_errors' : ''">
									    <input type="text" :name="inputName('document_number')" :value="patient.document_number" :disabled="disableInputs" @input="setPatient({document_number: $event.target.value})" v-mask="patientDocumentNumberMask" :placeholder="documentNumberPlaceholder">
                                    </div>
                                </div>
							</div>
						</div>
					</div>
				</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label"> Sexo biológico </label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
                                    <div w-100 :class="patientErrors.document_type.hasError ? 'field_with_errors' : ''">
                                        <vue-my-select
                                            v-model="patient.sex"
                                            :name="inputName('sex')"
                                            :options="genderOptions"
                                            :value="patient.sex"
                                            :disabled="disableInputs"
                                            :placeholder="'Selecione o gênero'"
                                        />
                                    </div>
								</div>
							</div>
						</div>
					</div>
                    <div class="col-3">
                        <label class="form-label"> Data de nascimento </label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
                                    <div w-100 :class="patientErrors.born_date.hasError ? 'field_with_errors' : ''">
									    <input type="date" :name="inputName('born_date')" :value="patient.born_date" :disabled="disableInputs" @input="setPatient({born_date: $event.target.value})">
                                    </div>
                                </div>
							</div>
						</div>
					</div>
                    <div class="col-6">
                        <label class="form-label"> Email </label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
                                    <div w-100 :class="patientErrors.email.hasError ? 'field_with_errors' : ''">
									    <input type="email" :name="inputName('email')" :value="patient.email" :disabled="disableInputs" @input="setPatient({email: $event.target.value})">
                                    </div>
                                </div>
							</div>
						</div>
					</div>
                </div>
                <div class="row">
					<div class="col-2">
                        <label class="form-label"> Idade </label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
									<input type="text" :name="inputName('age')" :disabled="true" :value="age">
								</div>
							</div>
						</div>
					</div>
					<div class="col-2">
                        <label class="form-label"> Peso (KG) </label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
                                    <div w-100 :class="patientErrors.weight.hasError ? 'field_with_errors' : ''">
									    <input type="text" :name="inputName('weight')" :value="patient.weight" :disabled="disableInputs" @input="setPatient({weight: $event.target.value})"  v-mask="['##.#', '###.#']" placeholder="##.#">
                                    </div>
                                </div>
							</div>
						</div>
					</div>
					<div class="col-2">
                        <label class="form-label"> Altura</label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
                                    <div w-100 :class="patientErrors.height.hasError ? 'field_with_errors' : ''">
									    <input type="text" :name="inputName('height')" :value="patient.height" :disabled="disableInputs" @input="setPatient({height: $event.target.value})" v-mask="'#.##'" placeholder="#.##">
                                    </div>
                                </div>
							</div>
						</div>
					</div>
                    <div class="col-3">
                        <label class="form-label"> Código externo </label>
						<div class="form-input">
							<div class="group-items">
								<div class="input-group">
                                    <div w-100 :class="patientErrors.external_code.hasError ? 'field_with_errors' : ''">
									    <input type="text" :name="inputName('external_code')" :value="patient.external_code" :disabled="disableInputs" @input="setPatient({external_code: $event.target.value})">
                                    </div>
                                </div>
							</div>
						</div>
                    </div>
				</div>
                <div class="row" mt=1>
					<div class="col-12">
						<h5><input
							type="checkbox"
							name="order_attendance[patient_attributes][minor]"
							:value="showResponsibleCard"
							v-model="showResponsibleCard"
                            :disabled="disableInputs"
							id="accept"
							class="form-check-input">
								Paciente necessita de responsável legal
						</h5>
					</div>
				</div>
                <div v-if="showResponsibleCard" id="patient-responsible-card" class="card" >
                    <div class="row" mt=1>
                        <div class="col-12">
                            <h4> Informações do responsável </h4>
                            <div class="row">
                                <div class="col-6">
                                    <label class="form-label"> Nome </label>
                                    <div class="form-input">
                                        <div class="group-items">
                                            <div class="input-group">
                                                <div w-100 :class="patientErrors.responsible_name.hasError ? 'field_with_errors' : ''">
                                                    <input type="text" :name="inputName('responsible_name')" :value="patient.responsible_name" :disabled="disableInputs" @input="setPatient({responsible_name: $event.target.value})">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <label class="form-label"> Tipo do documento </label>
                                    <div class="form-input">
                                        <div class="group-items">
                                            <div class="input-group">
                                                <div w-100 :class="patientErrors.responsible_document_type.hasError ? 'field_with_errors' : ''">
                                                    <vue-my-select
                                                        v-model="patient.responsible_document_type"
                                                        :name="inputName('responsible_document_type')"
                                                        :options="documentOptions"
                                                        :value="patient.document_type"
                                                        :disabled="disableInputs"
                                                        :placeholder="'Selecione o tipo do documento'"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <label class="form-label"> Número do documento </label>
                                    <div class="form-input">
                                        <div class="group-items">
                                            <div class="input-group">
                                                <div w-100 :class="patientErrors.responsible_document_number.hasError ? 'field_with_errors' : ''">
                                                    <input type="text" :name="inputName('responsible_document_number')"  :value="patient.responsible_document_number" :disabled="disableInputs" @input="setPatient({responsible_document_number: $event.target.value})" v-mask="responsibleDocumentNumberMask">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'
    import { GlobalAutocomplete } from '../http/autocomplete'
    import { processFieldsErrors } from '../../utilities/componentErrorUtilities'

    const globalAutocomplete = new GlobalAutocomplete()
    const storeModuleName = 'attendanceFormModule';

    export default {
        props: {
            baseInputName: {
                type: String,
                default: ''
            },
            documentOptions: {
                type: Array,
                default: []
            },
            genderOptions:{
                type: Array,
                default: []
            },
            initialPatient: {
                type: Object,
                default: {}
            },
            fieldsErrors: {
                type: Array,
                default: []
            },
            patientEdit: {
                type: Boolean,
                default: false
            },
            editable: false
        },
        beforeMount(){
            if(this.initialPatient.id == null){
                this.initialPatient.id = this.initialPatient.name;
                this.initialPatient.newInstance = true;
            }
            this.patientOptions.push(this.initialPatient);
            this.selectedPatientId = this.initialPatient.id;
            let fieldsError = {
                name: {
                    hasError: false,
                    message: "",
                },
                document_type: {
                    hasError: false,
                    message: "",
                },
                document_number: {
                    hasError: false,
                    message: "",
                },
                sex: {
                    hasError: false,
                    message: "",
                },
                born_date: {
                    hasError: false,
                    message: "",
                },
                email: {
                    hasError: false,
                    message: "",
                },
                weight: {
                    hasError: false,
                    message: "",
                },
                height: {
                    hasError: false,
                    message: "",
                },
                external_code: {
                    hasError: false,
                    message: ""
                },
                responsible_name: {
                    hasError: false,
                    message: "",
                },
                responsible_document_type: {
                    hasError: false,
                    message: "",
                },
                responsible_document_name: {
                    hasError: false,
                    message: "",
                },
                responsible_document_number: {
                    hasError: false,
                    message: "",
                }
            }
            processFieldsErrors(fieldsError, this.fieldsErrors)
            this.setPatientErrors({...fieldsError})
        },
        data(){
            return {
                showResponsibleCard: false,
                selectedPatientId: null,
                patientOptions: []
            }
        },
        computed: {
            mapPatientOptions(){
                return this.patientOptions.map((patient)=>{
                    return {text: patient.display, value: patient.id, name: patient.name, newInstance: patient.newInstance}
                })
            },
            disableInputs(){
                return this.patient.id != null && this.patient.newInstance == null && !this.editable  && !this.patientEdit
            },
            patientDocumentNumberMask(){
                return this.documentNumberMask('document_type');
            },
            responsibleDocumentNumberMask(){
                return this.documentNumberMask('responsible_document_type');
            },
            documentNumberPlaceholder(){
                if(this.patient.document_type == 'cpf')
                    return '###.###.###-##'
            },
            age(){
                if(this.patient.born_date){
                    var bornDate = new Date(this.patient.born_date);
                    var timeDifference = Date.now() - bornDate.getTime();
                    var ageDate = new Date(timeDifference)
                    return Math.abs(ageDate.getUTCFullYear() - 1970);
                }
            },
            ...mapGetters(storeModuleName, ['patient', 'patientErrors'])
        },
        methods: {
            inputName(attribute){
                return `${this.baseInputName}[${attribute}]`;
            },
            findPatient(searchQuery, id){
                globalAutocomplete.autocomplete('patients', searchQuery).then(response=>{
                    this.patientOptions = response.data
                })
            },
            documentNumberMask(basedAttribute){
                if(this.patient[basedAttribute] == 'CPF')
                    return ['###.###.###-##']
                if(this.patient[basedAttribute] == 'Passaporte')
                    return ['XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX']
                return '###########################'
            },
            ...mapActions(storeModuleName, ['setPatient', 'setPatientErrors'])
        },
        watch:{
            selectedPatientId(newVal, oldVal){
                var _patient = this.patientOptions.find(patient=>patient.id == this.selectedPatientId);
                this.setPatient({
                    newInstance: null,
                    document_type: null,
                    document_number: null,
                    sex: null,
                    born_date: null,
                    email: null,
                    weight: null,
                    height: null,
                    external_code: null,
                    responsible_name: null,
                    responsible_document_type: null,
                    responsible_document_name: null,
                    ..._patient
                });
            }
        }
    }
</script>