<template>
  <div :class="componentClass" :id="id">
    <div flex-box flex-column-direction flex-gap=2>
      <span class="font-primary pointer text-align-right" @click="close" >Fechar</span>
      <i class="font-primary-dark" :class="icon" font-type=bold font-size=2 weight=6>&nbsp;{{ title }}</i>
      <div class="drawer-content" ><slot></slot></div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    id: {
      type: String,
      default: 'vue-drawer-component'
    },
    title: {
      type: String,
      default: 'Title'
    },
    icon: {
      type: String,
      default: 'bi bi-easel'
    }
  },
  data(){
    return {
      opened: false
    }
  },
  computed: {
    componentClass(){
      return {
        'vue-drawer-component': true,
        'closed': !this.opened
      }
    }
  },
  methods: {
    open(){
      this.opened = true;
    },
    close(){
      this.opened = false;
    }
  }
}
</script>