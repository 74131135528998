
<template>
    <div>
        <label class="form-label">Quantidade*</label>
        <div class="form-input">
            <div class="group-items">
                <div class="input-group">
                    <input type="number" min="1" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][quantity]`" v-model="selectedQuantity"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props : {
        quantity : {
            type : Number,
            default : () => 1
        },
        index : null
    },
    data () {
        return {
            selectedQuantity : this.quantity
        }
    }
}
</script>
