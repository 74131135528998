<template>
  <multiselect
    v-model="localSelectedDocumentTypes"
    id="ajax" 
    label="name" 
    track-by="required_document_type_id"
    placeholder="Digite para pesquisar" 
    open-direction="bottom" 
    :options="documentTypes"
    :multiple="true"
    :searchable="true" 
    :showNoResults="false"
    :loading="isLoading" 
    :internal-search="false" 
    :close-on-select="true" 
    :options-limit="300" 
    :limit="3" 
    :max-height="600" 
    :show-no-results="false" 
    @search-change="asyncFind"
    @select="handleSelect"
    @remove="handleRemove"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <span class="multiselect__tag">
          <span>{{ option.name }}</span> 
          <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
        </span>
      </template>
      <template slot="clear" slot-scope="props">
        <div class="multiselect__clear" v-if="hasSelectedDocumentTypes" @mousedown.prevent.stop="clearAllData(props.search)"></div>
      </template>
      <span slot="noResult">Nenhum tipo de documento encontrado com este termo.</span>
      <span slot="noOptions">Digite para pesquisar.</span>
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { GlobalAutocomplete } from '../http/autocomplete'

const globalAutocomplete = new GlobalAutocomplete()

export default {
  components:{
    Multiselect
  },
  props: {
    selectedDocumentTypes: {
      type: Array,
      default: function(){
        return [];
      }
    },
    autocompleteUri : {
      type : String,
      default : () => ""
    }
  },
  data: function (){
    return {
      isLoading: false,
      documentTypes: [],
      localSelectedDocumentTypes: [],
      uri: ""
    }
  },
  computed: {
    hasSelectedDocumentTypes: function(){
      return this.localSelectedDocumentTypes.length>0
    }
  },
  methods: {
    asyncFind (query) {
      if(query.length>3){
        this.isLoading = true
        globalAutocomplete.autocompleteWithEndpoint(this.uri, query).then(response => {
          this.documentTypes = response.data.document_types;
          this.isLoading = false;
        });
      }
    },
    handleSelect(selectOption){
      this.$emit('select', selectOption)
    },
    handleRemove(removedOption){
      this.$emit('remove', removedOption)
    }
  },
  beforeMount(){
    this.localSelectedDocumentTypes = [...this.selectedDocumentTypes]
    this.uri = this.autocompleteUri;
  }
}
</script>
