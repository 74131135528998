<template>
  <div class="document-editor" :ref="refName" :class="editorClass">
    <div class="document-editor__toolbar"></div>
    <div class="document-editor__editable-container">
      <input type="hidden" :name="name"  v-model="editorData" :disabled="disabled">
      <ckeditor :editor="editorType" @ready="onCkEditorReady" v-model="editorData" :config="editorConfig" ></ckeditor>
    </div>
  </div>
</template>

<script>
  import '@ckeditor/ckeditor5-build-decoupled-document';

  export default {
    data(){
      return {
        editorData: '',
        editorType: DecoupledEditor
      }
    },
    props:{
      name: '',
      initialData: '',
      editorConfig: {
        type: Object,
        default: () =>{ }
      },
      editorClass: '',
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      refName(){
        return 'my-ckeditor-container'
      }
    },
    beforeMount(){
      this.editorData = this.initialData;
    },
    methods: {
      onCkEditorReady(editor){
        const toolbarContainer = this.$refs[this.refName].querySelector('.document-editor__toolbar' );
        toolbarContainer.appendChild( editor.ui.view.toolbar.element );
      },
    }
  }
</script>
