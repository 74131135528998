<template>
    <div class="form-input my-datepicker-container">
        <div class="group-items">
            <div class="input-group my-datepicker-input-container">
				<input type="hidden" :name="name" :value="valueString">
                <datepicker :input-class="inputClass" v-model="value" :format="format" :id="id" :placeholder="placeholder"></datepicker>					
                <button v-if="!!value" type="button" class="clear-date" @click="handleClearDate">
                    <span class="bi bi-x-circle-fill"></span>
                </button>
            </div>
        </div>
    </div>    
</template>
<script>
import Datepicker from 'vuejs-datepicker'

export default {
    components:{
        Datepicker
    },
    model:{
        prop: 'initialValue',
        event: 'change'
    },
    props: {
        format: {
            type: String,
            default: 'dd/MM/yyyy'
        },
        name: {
            type: String,
            default: ''
        },
        initialValue: {
            type: Date,
            default: null
        },
        id: {
            type: String,
            initial: ''
        },
        time: {
            type: String, 
            initial: '',
        },
        inputClass: {
            type: String,
            initial: 'custom-input'
        },
        placeholder: {
            type: String,
            initial: ''
        }
    },
    data(){
        return {
            value: this.initialValue,
        }
    },
    computed: {
        valueString: function(){
            if(!!this.value){
                return this.value.toJSON();
            }
        }
    },
    watch: {
        value: function(newValue, oldValue){
            if(!oldValue){
                this.setTime(this.time)
            }
            this.$emit('change', newValue);
        },
        time: function(newValue){
            this.setTime(newValue)
        }
    },
    methods: {
        setTime(time){
            if(!!this.value && !!time && /^[0-2][0-9]:[0-5][0-9]$/.test(time)){
                const timeSplit = time.split(":")
                let newDate = new Date(this.value.getTime())
                newDate.setHours(parseInt(timeSplit[0]), parseInt(timeSplit[1]))
                this.value = newDate;
            }
        },
        handleClearDate(){
            this.value=null
        }
    },
    beforeMount(){
        this.setTime(this.time)
    }
}
</script>
