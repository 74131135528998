<template>
    <div>
        <label class="form-label">Data/Hora da coleta</label>
        <div class="form-input">
            <div class="group-items">
                <div class="input-group">
                    <!-- <datepicker 
                        v-model="selectedCollectionDate"
                        :input-class="'custom-input'"></datepicker> -->
                    <input type="datetime-local" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][collection_date]`" v-bind:value="selectedCollectionDate"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Datepicker
    },
    props : {
        collectionDate : "",
        index : null
    },
    data () {
        return {
            selectedCollectionDate : this.collectionDate,
            collectioHour : ""
        }
    }
}
</script>