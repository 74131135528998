<template>
  <div>
    <label class="form-label" for="ajax">Material coletado*</label>
    <multiselect 
      v-model="material"
      id="ajax" 
      label="name" 
      track-by="name"
      placeholder="Digite para pesquisar"
      open-direction="bottom" 
      :options="materials"
      :multiple="false"
      :searchable="true" 
      :hideSelected="true"
      :showNoResults="false"
      :loading="isLoading" 
      :internal-search="false"
      :clear-on-select="true"
      :taggable="false" 
      :close-on-select="true" 
      :options-limit="300" 
      :limit="3" 
      :max-height="600" 
      :show-no-results="false"
      :hide-selected="true" 
      @search-change="asyncFind"
      @select="selectHandle"
    >
        <template slot="tag" slot-scope="{ option, remove }">
            <span class="custom__tag">
            <span>{{ option.name }}</span>
            <span class="custom__remove" @click="remove(option)">❌</span>
            </span>
        </template>
        <span slot="noResult">Nenhum registro encontrado com este termo.</span>
        <span slot="noOptions">Digite para pesquisar.</span>
    </multiselect>
    <input v-if="material" v-bind:name="`order_attendance[order_exam_orders_attributes][${index}][material_id]`" type="hidden" v-bind:value="material.id"/>
  </div>
</template>

<script>

    import Multiselect from 'vue-multiselect'
    import { store } from '../store'
    
    import { GlobalAutocomplete } from '../../http/autocomplete'
    const globalAutocomplete = new GlobalAutocomplete()

    export default {
        store,
        components: {
            Multiselect
        },
        props: {
            selected : {
                type: Object,
                default : () => {}
            },
            initialOptions: {
                type: Array,
                default: () => []
            },
            index: null
        },
        data () {
            return {
                material: this.selected,
                materials: [],
                isLoading: false
            }
        },
        methods : {
            asyncFind (query) {
                if(query.length >= 3) {
                    this.isLoading = true
                    globalAutocomplete.autocomplete('materials',query).then(response => {
                        this.materials = response.data.materials
                        this.isLoading = false
                    })
                }else if(query.length < 3){
                    this.materials = this.initialOptions;
                }
            },

            clearAll () {
                store.dispatch({type: 'clearAll', data: null})
            },
            selectHandle (selected) {
                this.material = selected
            }
        },
        beforeMount(){
            this.materials = this.initialOptions;
        }
    }
  
</script>