<script>
export default {
    data: function(){
        return {
            opened: true,
        }
    },
    props:{
        alwaysOpen:{
            type: Boolean,
            default: false
        },
        startOpened: {
            type: Boolean,
            default: true
        },
        toggleIcon:{
            type: String,
            default: "bi bi-chevron-up"
        },
        title: {
            type: String,
            default: 'Titulo'
        },
        icon: {
            type: String,
            default: 'bi bi-info-circle'
        },
        onExpand:{
            type: Function,
            default: ()=>{}
        },
        onColapse: {
            type: Function,
            default: ()=>{}
        }
    },
    computed: {
        bodyStyle: function (){
            if(this.isOpen){
                return {};
            } else {
                return {display: 'none'};
            }
        },
        isOpen: function(){
            return this.$data.opened;
        },
        toggleClass: function(){
            if(this.isOpen){
                return 'expanded';
            } else {
                return 'colapsed';
            }
        },
        bodyClass: function(){
            if(this.isOpen){
                return 'expanded';
            } else {
                return 'colapsed';
            }
        }
    },
    methods: {
        toggle(){
            if(!this.alwaysOpen){
                if(this.isOpen == true)
                    this.colapse();
                else 
                    this.expand();
            }
        },
        expand(){
            this.setOpened(true);
            this.onExpand();
        },
        colapse(){
            this.setOpened(false);
            this.onColapse();
        },
        setOpened(value){
            this.$data.opened = value;
        }
    },
    beforeMount: function() {
        this.setOpened(this.startOpened);
    }
}
</script>

<template>
    <div accordion-component >
        <div header ui-row @click="toggle" class="space-between">
            <i :class="icon"> {{ title }} </i>
            <div v-if="!alwaysOpen" class="font-bold" :class="toggleClass">
                <i toggle-icon :class="toggleIcon" aria-hidden="true"></i>
            </div>
        </div>
        <div body :style="bodyStyle" p=1>
            <slot class="colapsed"></slot>
        </div>
    </div>
</template>