import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import TurbolinksAdapter from 'vue-turbolinks';

// Import Plugins
import MainPlugin from '../../javascripts/plugins/main';
import { ApiService } from '../../javascripts/services/api.js.erb';

// Import Store
import { globalStore } from './globalStore';

// Import Third Partie Libs
import VueTheMask from 'vue-the-mask'
import Multiselect from 'vue-multiselect';
import money from 'v-money'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueToast from 'vue-toast-notification';

// Import Indexes
import '../../images/index.js.erb';
import '../../javascripts/components/index.js.erb';

Vue.use(TurbolinksAdapter)
Vue.use(MainPlugin);
Vue.use(Vuex)
Vue.use(money, {precision: 2})
Vue.use(CKEditor);
Vue.use(VueToast);

// Register Third Parties Libs
Vue.use(VueTheMask)
Vue.component('vue-multiselect', Multiselect);

Vue.prototype.$apiService = ApiService
const store = new Vuex.Store(globalStore);

document.addEventListener('turbolinks:load', () => {
    const element = document.getElementById('appAdmin');
    if (element != null) {
        const app = new Vue({
            el: '#appAdmin',
            store: store
        });
    }
})
