
import axios from 'axios'


export class ExamsApi {
  constructor(){
    axios.defaults.headers = {'Content-Type': 'application/json'}
    this.http = axios;
  }

  getRequiredDocuments(examLaboratoryId) {
    var _url = '/admin/core/exam_laboratories/'+examLaboratoryId+'/required_documents'
    return this.http({
      url: _url,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
      },
      responseType: 'json'
    }).catch(e => {
      return e.response;
    });
  }
}