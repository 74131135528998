<script>
import { mapActions, mapState } from 'vuex'
export default {
    computed:{
        toogleButtonIcon(){
            if(this.collapsed)
                return 'bi bi-chevron-double-right'
            return 'bi bi-chevron-double-left'
        },
        ...mapState('sidebardModule', ['activeItem', 'collapsed'])
    },
    methods: {
        ...mapActions('sidebardModule', ['increaseActiveItem', 'toogleCollapse', 'notCollapsed'])
    }
}
</script>