<script>
export default {
  data: function (){
    return {
      filename: "",
      createdAt: ""
    }
  },
  methods: {
    handleChangeUpload(event){
      let filePath = event.target.value.split("\\");
      let fileName = filePath[(filePath.length-1)]
      let date = new Date();
      let monthFormat = (date.getMonth()<9) ? `0${date.getMonth()+1}` : date.getMonth()+1
      this.filename = fileName;
      this.createdAt = `${date.getDate()}/${monthFormat}/${date.getFullYear()}`
    },
    removeFile(){
      this.filename="";
      this.createdAt="";
      this.$refs.uploadedFile.value=""
    }
  }
}
</script>