<template>
    <div w-100>
        <input type="hidden" :name="name" :value="value">
        <input type="text" disabled :id="id" :value="display">
    </div>
</template>
<script>
export default{
  props: {
    name: String,
    id: String,
    value: {
      type: String,
      default: ""
    },
    display: {
      type: String,
      default: ""
    }
  }
}
</script>
