<template>
  <div>
    <div id="exams-step"  mt=1>
      <div v-if="hasSelectedExams">
        <div class="card exam-card" v-for="(item, index) in selectedExams" :key="item.id" p=0 mb=1>
          <div class="card-container" p=1>
            <div class="card-body">
              <div ui-row>
                <div ui-col col=9>
                  <h5><b>{{itemDisplay(item)}}</b></h5>
                  <h5 class="font-gray" font-size=1>{{ itemExternalDisplay(item) }}</h5>
                  <h6 class="" mb=0 weight=6 mt=2>Prazo: {{ item.deadline }} dias a partir da chegada do exame ao laboratório </h6>
                </div>
                <div ui-col col=3>
                  <div flex-box bottom-right flex-wrap flex-column-direction>
                    <h5 mb=0.5> Preço do laboratório </h5>
                    <h5 weight=6> 
                      <span v-if="item.number_currency_price">{{ item.number_currency_price }}</span>
                      <span v-else>Preço não cadastrado</span>
                    </h5>
                  </div>
                  <div v-if="notPartnerPrice" flex-box bottom-right flex-wrap flex-column-direction>
                    <h5 class="font-primary"  mb=0.5> Preço de venda </h5>
                    <h5 class="font-primary" weight=6 mb=0> {{ item.number_currency_sell_price }}</h5>
                  </div>
                </div>
              </div>
              <div ui-row ui-col>
              </div>
            </div>
          </div>
          <input
            :id="`selected_exams_${index}`"
            :name="`selected_exams[]`"
            type="hidden" :value="item.id"/>
        </div>
        <div v-if="hasSelectedExams" class="sum-footer" flex-box flex-space="between" weight=6 pr=2 pl=2>
          <span> Prazo total: {{ examsMaxDeadline }} dias a partir da chegada das amostras ao laboratório </span>
          <div flex-box bottom-right flex-wrap flex-column-direction>
            <span> Preço total de custo: {{ examsTotalPrice}}  </span>
            <span v-if="notPartnerPrice" class="font-primary" mt=1> Preço total de venda: {{ examsTotalSellPrice }}  </span>
          </div>
        </div>
      </div>
      <div v-else class="empty-step-container">
        <h1> Nenhum exame adicionado </h1>
      </div>
    </div>
  </div>
</template>

<script>

    import { mapGetters, mapState, mapActions } from 'vuex';
    const storeModuleName = 'attendanceFormModule';

    export default {
        props: {
          notPartnerPrice : {
            type: Boolean,
            default: () => false
          }
        },
        computed: {
          ...mapState(storeModuleName, ['selectedExams']),
          ...mapGetters(storeModuleName, ['examsTotalPrice', 'examsTotalSellPrice', 'examsMaxDeadline', 'hasSelectedExams'])
        },
        methods : {
          itemDisplay(item){
            return `#${item.code} - ${ item.name }`;
          },
          itemExternalDisplay(item){
            var itemDisplay = this.itemDisplay(item);
            if(item.external_code || item.external_name)
              itemDisplay =  `#${item.external_code} - ${ item.external_name }`;
            if(item.laboratory_name)
              itemDisplay += ` - ${item.laboratory_name}`;
            return itemDisplay
          },
          getDeliveryDate(deadline) {
              let date = new Date();
              date.setDate(date.getDate() + deadline)
              let year = date.getFullYear()
              let month = date.getMonth() + 1
              let day = date.getDate()
              let fullDay = day.toString().length == 1 ? '0'+day : day
              let fullMonth = month.toString().length == 1 ? '0'+month : month
              return year + "-" + fullMonth + "-" + fullDay
          },
          ...mapActions(storeModuleName, ['clearAll', 'removeSelectedExam'])
        }
    }
</script>