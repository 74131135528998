<template>
  <div w-100>
    <template v-for="(exam, index) in localSelectedExams">
      <input type="hidden" :name="examFieldName(index)" :key="index" :value="exam.id">
    </template>
    <multiselect
      v-model="localSelectedExams"
      id="ajax" 
      label="exam_name" 
      track-by="id"
      placeholder="Digite para pesquisar" 
      open-direction="bottom" 
      :options="options"
      :multiple="true"
      :searchable="true" 
      :showNoResults="false"
      :internal-search="false" 
      :close-on-select="true" 
      :options-limit="300" 
      :limit="3"
      :max-height="600" 
      :show-no-results="false"
      :disabled="disabled" 
      >
        <template slot="tag" slot-scope="{ option, remove }">
          <span class="multiselect__tag">
            <span>{{option.exam_code}}</span> 
            <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
          </span>
        </template>
        <span slot="noResult">Nenhum exame encontrado com este termo.</span>
        <span slot="noOptions">Nenhum exame disponível. Digite para pesquisar.</span>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'

export default{
  components:{
    Multiselect
  },
  props: {
    options: {
      type: Array,
      default: function (){
        return [];
      }
    },
    initial: {
      type: Array,
      default: function (){
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function(){
    return {
      localSelectedExams: [],
    }
  },
  methods: {
    examFieldName: function(index){
      return `order_new_collect[exam_laboratory_new_collects_attributes][${index}][order_exam_laboratory_id]`
    }
  },
  beforeMount(){
    this.localSelectedExams = this.initial
  }
}
</script>
