<template>
<div id="doctor-step">
    <div class="row">
        <div class="col-6">
            <label class="form-label"> Nome </label>
            <div class="form-input">
                <div class="group-items">
                    <div class="input-group">
                        <div w-100 :class="doctorErrors.name.hasError ? 'field_with_errors' : ''">
                            <template v-if="!isEditing">
                                <vue-my-select
                                    v-model=doctorIdValue
                                    :name="vueSelectName"
                                    :options="mapDoctorOptions"
                                    :placeholder="'Pesquise pelo nome ou CRM'"
                                    :searchable="true"
                                    :close-on-select="true"
                                    :async-find="findDoctor"
                                    :icon="'search'"
                                >
                                    <template slot="singleLabel" slot-scope="{ option }">
                                        {{option.name}}
                                    </template>
                                </vue-my-select>
                            </template>
                            <template v-else>
                                <input :disabled="disableFields" type="text" :name="inputName('name')" v-model="doctor.name">
                            </template>
                        </div>
                        <template v-if="isEditing">
                            <input v-if="!disableInputs" :disabled="disableFields" type="hidden" :name="'doctor[id]'" v-model="doctorIdValue">
                        </template>
                        <template v-else>
                            <input v-if="!disableInputs" :disabled="disableFields" type="hidden" :name="inputName('name')" v-model="doctor.name">
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <label class="form-label"> CRM </label>
            <div class="form-input">
                <div class="group-items">
                    <div class="input-group">
                        <div w-100 :class="doctorErrors.register.hasError ? 'field_with_errors' : ''">
                            <input type="text" :name="inputName('register')" :disabled="disableFields" v-model="doctorRegister" v-mask="'######'">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <label class="form-label"> UF </label>
            <div class="form-input">
                <div class="group-items">
                    <div class="input-group">
                        <div w-100 :class="doctorErrors.state.hasError ? 'field_with_errors' : ''">
                            <vue-my-select
                                v-model="doctor.state_id"
                                :name="inputName('state_id')"
                                :options="stateOptions"
                                :options-limit=28
                                :value="doctor.state_id"
                                :placeholder="'Selecione um Estado'"
                                :disabled="disableFields"
                                :disable-input="disableInputs"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label class="form-label">Especialidade</label>
            <div class="form-input">
                <div class="group-items">
                    <div class="input-group">
                        <div w-100 :class="doctorErrors.specialty.hasError ? 'field_with_errors' : ''">
                            <vue-my-select
                                v-model="doctor.specialty_id"
                                :name="inputName('specialty_id')"
                                :options="specialtyOptions"
                                :value="doctor.specialty_id"
                                :placeholder="'Selecione uma Especialidade'"
                                :disabled="disableFields"
                                :clear-on-select="true"
                                :allow-empty="false"
                                :preserve-search="true"
                                :searchable="true"
                                :disable-input="disableInputs"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <label class="form-label"> E-mail </label>
            <div class="form-input">
                <div class="group-items">
                    <div class="input-group">
                        <div w-100 :class="doctorErrors.email.hasError ? 'field_with_errors' : ''">
                            <input type="text" :name="inputName('email')" :disabled="disableFields" v-model="doctorEmail">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template v-if="!isEditing">
        <div class="row" mt=1>
            <div class="col-12">
                <h5> <input type="checkbox" name="order_attendance[has_not_required_doctor]" v-model="dontHasDoctor" class="form-check-input"> Não possuo médico solicitante </h5>
            </div>
        </div>
    </template>
</div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'
    import { GlobalAutocomplete } from '../http/autocomplete'
    import { processFieldsErrors } from '../../utilities/componentErrorUtilities'
    const globalAutocomplete = new GlobalAutocomplete()

    const storeModuleName = 'attendanceFormModule';

    export default {
        props: {
            baseInputName: {
                type: String,
                default: ''
            },
            stateOptions: {
                type: Array,
                default: []
            },
            specialtyOptions: {
                type: Array,
                default: []
            },
            initialDoctor: {
                type: Object,
                default: {}
            },
            fieldsErrors: {
                type: Array,
                default: []
            },
            permitCreateNewDoctor: {
                type: Boolean,
                default: true,
            },
            isEditing: {
                type: Boolean,
                default: false
            },
            disableInputs: true,
            hasNotRequiredDoctorValue: false
        },
        beforeMount(){
            let errorFields = {
                name: {
                    hasError: false,
                    message: "",
                },
                register: {
                    hasError: false,
                    message: "",
                },
                state: {
                    hasError: false,
                    message: "",
                },
                specialty: {
                    hasError: false,
                    message: "",             
                },
                email: {
                    hasError: false,
                    message: "",           
                }
            }
            processFieldsErrors(errorFields, this.fieldsErrors);
            if(this.initialDoctor.id == null){
                this.initialDoctor.id = this.initialDoctor.name;
                this.initialDoctor.newInstance = true;
            }
            this.doctorOptions.push(this.initialDoctor);
            this.selectedDoctorId = this.initialDoctor.id;
            this.setDoctorErrors({...errorFields})
        },
        data(){
            return {
                dontHasDoctor: this.hasNotRequiredDoctorValue,
                doctorOptions: [],
                selectedDoctorId: null
            }
        },
        computed: {
            mapDoctorOptions(){
                return this.doctorOptions.map((doctor)=>{
                    var _map = {text: doctor.display, value: doctor.id, name: doctor.name, newInstance: doctor.newInstance};
                    return _map;
                });
            },
            disableFields(){
                if(this.isEditing){
                    return this.disableInputs;
                }
                return (this.dontHasDoctor || this.disableInputs ) || (this.doctor.id && !this.doctor.newInstance)
            },
            doctorRegister: {
                get(){
                    return this.doctor.register;
                },
                set(value){
                    this.setDoctor({register: value});
                }
            },
            doctorEmail: {
                get(){
                    return this.doctor.email;
                },
                set(value){
                    this.setDoctor({email: value});
                }
            },
            vueSelectName: function(){
                return this.isEditing ? this.inputName('id') : 'order_attendance[doctor_id]';
            },
            doctorIdValue: {
                get(){
                    return !this.doctor.newInstance ? this.doctor.id : null
                },
                set(value){
                    this.selectedDoctorId = value
                }
            },
            ...mapGetters(storeModuleName, ['doctor', 'doctorErrors'])
        },
        methods: {
            inputName(attribute){
                return `${this.baseInputName}[${attribute}]`;
            },
            findDoctor(searchQuery, id){
                if(searchQuery.length > 3){
                    globalAutocomplete.autocomplete('doctors',searchQuery, this.permitCreateNewDoctor).then(response => {
                        this.doctorOptions = response.data;
                    })
                }
            },
            ...mapActions(storeModuleName, ['setDoctor', 'setDoctorErrors'])
        },
        watch:{
            selectedDoctorId(newVal, oldVal){
                var _doctor = this.doctorOptions.find(doctor=>doctor.id == this.selectedDoctorId);
                this.setDoctor({newInstance: null, clinic_id: null, laboratory_id: null, register: '', specialty_id: '', state_id: '', ..._doctor});
            }
        }
    }
</script>