<template>
      <multiselect
        v-model="rolesSelected"
        id="ajax" 
        label="label" 
        track-by="key"
        open-direction="bottom"
        placeholder="Selecione uma opção"
        select-label="Enter para selecionar"
        deselect-label="Enter para remover"
        :options="options"
        :multiple="true"
        :searchable="false" 
        :showNoResults="false"
        :internal-search="false" 
        :close-on-select="true" 
        :max-height="600" 
        :show-no-results="false"
        @select="handleSelect"
        @remove="handleRemove"
        >
          <template slot="tag" slot-scope="{ option, remove }">
            <span class="multiselect__tag">
              <span>{{option.label}}</span> 
              <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
            </span>
          </template>
      </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  components:{
    Multiselect
  },
  props: {
    roleOptions: String,
    selected: {
      type: Array,
      default: function(){
        return []
      }
    }
  },
  data: function(){
    return {
      options: [],
      rolesSelected: []
    }
  },
  methods: {
    handleSelect(selectOption){
      this.$emit('select', selectOption)
    },
    handleRemove(removedOption){
      this.$emit('remove', removedOption)
    }
  },
  beforeMount(){
    this.options = JSON.parse(this.roleOptions)
    this.rolesSelected = [...this.selected]
  }
}
</script>
