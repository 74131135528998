<template>
    <div class="sidebar-item" pr=1em>
        <a :class="itemClass" item :href="itemUrl" @click="toggle" :subItem="subItem">
            <div class="icon" column :title="name">
                <i :class="icon"></i>
            </div>
            <div v-if="!collapsed" class="icon" column>
                <span>
                {{name}}
                </span>
                <span v-if="hasRightIcon">
                    <i :class="rightIcon"></i>
                </span>
            </div>
            <div v-if="group && !collapsed" column >
                <i :class="toggleIcon"></i>
            </div>
        </a>
        <slot v-if="open && group"></slot>
    </div>
</template>

<script>
export default {
    data(){
        return {
            open: false
        }
    },
    props:{
        collapsed: {
            type: Boolean,
            default: false
        },
        url: String,
        icon: String,
        name: String,
        group: {
            type: Boolean,
            default: false
        },
        subItem: {
            type: Boolean,
            default: false
        },
        active: {
            type: String,
            default: 'false',
            validator: (value)=>{
                return ['true', 'false'].includes(value);
            }
        },
        hasRightIcon: {
            type: Boolean,
            default: false,
        },
        rightIcon: {
            type: String,
            default: ''
        }
    },
    computed:{
        toggleIcon(){
            if(this.open == true)
                return 'bi bi-chevron-up'
            return 'bi bi-chevron-down'
        },
        itemUrl(){
            if(this.url != '')
                return this.url;
            return false
        },
        itemClass(){
            return {
                "sidebar-item__container": true,
                "pointer": true,
                "active-item": (this.active == 'true'),
                "active-background": (this.active == 'true' && this.group == false)
            }
        }
    },
    watch: {
        collapsed(oldValue, newValue){
            this.open=newValue;
        }
    },
    methods: {
        toggle(){
            this.open = !this.open
            if(this.open){
                this.$emit('open')
            }
        }
    },
    beforeMount(){
        if(!!this.url){
            this.open=true;
        }
    }
}
</script>