<template>
  <div>
    <h4>Exames</h4>
    <div ui-row>
      <div ui-col col-xl="12" col-lg="12">
        <div>
          <label class="form-label">Exames</label>
          <div class="form-input">
            <div class="group-items">
              <div class="input-group">
                <vue-select-exams :selected-exams="selectedExams" @select="handleSelect" @remove="handleDelete"></vue-select-exams>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <template v-for="(priceTableItem, index) in pricesTableItems">
        <div v-if="priceTableItem._destroy!=1" ui-row :key="index" class="mt-3 card">
          <input type="hidden" :name="inputName(index, 'exam_laboratory_id')" v-model="priceTableItem.exam_laboratory.id">
          <input type="hidden" :name="inputName(index, 'id')" v-model="priceTableItem.id">
          <div ui-col col-xl="12" col-lg="12">
            <h5>{{ priceTableItem.exam_laboratory.name }}</h5>
          </div>
          <div ui-col col-xl="6" col-lg="6">
            <label class="form-label" :for="inputId(index, 'deadline')">Prazo</label>
            <div class="form-input">
              <div class="group-items">
                <div class="input-group">
                  <input type="text" v-model="priceTableItem.deadline" :name="inputName(index, 'deadline')" :id="inputId(index, 'deadline')">
                </div>
              </div>
            </div>
          </div>
          <div ui-col col-xl="6" col-lg="6">
            <label class="form-label" :for="inputId(index, 'price')">Preço</label>
            <div class="form-input">
              <div class="group-items">
                <div class="input-group">
                  <vue-my-money :initial-value="priceTableItem.price" :id="inputId(index, 'price')" :name="inputName(index, 'price')"></vue-my-money>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else :key="index">
          <input :key="index" type="hidden" :name="inputName(index, '_destroy')" v-model="priceTableItem._destroy">
          <input type="hidden" :name="inputName(index, 'id')" v-model="priceTableItem.id">
          <input type="hidden" :name="inputName(index, 'exam_laboratory_id')" v-model="priceTableItem.exam_laboratory.id">
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    initialData: {
      type: String,
      default: '',
    }
  },
  data: function(){
    return {
      pricesTableItems: []
    }
  },
  computed: {
    selectedExams: function(){
      if(!!this.pricesTableItems){
        return this.pricesTableItems.map(item=>item.exam_laboratory)
      }
      return []
    }
  },
  methods: {
    inputName: function(id, attribute){
      return `core_price_table[exam_laboratory_price_tables_attributes][${id}][${attribute}]`
    },
    inputId: function(id, attribute){
      return `core_price_table_exam_laboratory_price_tables_attributes_${id}_${attribute}`
    },
    handleSelect: function(selectedExam){
      this.pricesTableItems.push({
        code: selectedExam.code,
        deadline: selectedExam.deadline,
        exam_laboratory: selectedExam
      })
    },
    handleDelete: function(deletedExam){
      this.pricesTableItems = this.pricesTableItems.map(item => {
        if(!!item.id && item.exam_laboratory.id == deletedExam.id){
          item={...item, _destroy: 1}
        }
        return item
      }).filter(item => !!item.id || item.exam_laboratory.id != deletedExam.exam_laboratory.id)
    },
  },
  beforeMount(){
    this.pricesTableItems = JSON.parse(this.initialData).map(item => 
      ({
        id: item.id,
        deadline: item.deadline,
        price: parseFloat(item.price).toFixed(2),
        exam_laboratory: item.exam_laboratory
      })
    )
  }
}
</script>
