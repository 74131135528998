<template>
  <vue-my-select
    :name="'laboratory_id'"
    :options="laboratories"
    :placeholder="'Buscar pelo nome do laboratório'"
    :searchable="true"
    v-model="selectedLaboratoryId"
    :async-find="asyncFind"
    :on-select="onSelect"
  />
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import { GlobalAutocomplete } from '../http/autocomplete';

  const globalAutocomplete = new GlobalAutocomplete()

  export default {
    components:{
      Multiselect
    },
    props: {
      value: null,
      options: []
    },

    data: function() {
      return {
        term: "",
        laboratories: [],
        selectedLaboratoryId: null
      }
    },
    methods: {
      submitHandler: function(event) {
        event.preventDefault();
      },

      asyncFind (query) {
        if(query.length > 3) {
          globalAutocomplete.autocomplete('laboratories', query).then(response => {
            this.laboratories = response.data.laboratories;
          });
        }
      },

      onSelect(selectedOption) {
        this.selectedLaboratoryId = selectedOption.id;
      },

      submitSearch() {
        this.$refs.filters_form.submit();
      }
    },
    beforeMount() {
      this.laboratories = this.options;
      this.selectedLaboratoryId = this.value;
    }
  }
</script>