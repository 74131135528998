<script>
export default {
    install (Vue, options) {
      Vue.prototype.openModal = function(id){
        var modalElement = this.$root.$children.find(child => {return child.$attrs.id == id;});
        modalElement.open()
      }
      Vue.prototype.closeModal = function(id){
        var modalElement = this.$root.$children.find(child => {return child.$attrs.id == id;});
        modalElement.close();
      }
      Vue.prototype.copyTextToClipboard = function(text){
        navigator.clipboard.writeText(text);
      }
      Vue.prototype.submitForm = function(id){
        if(document.forms.length == 1)
          return document.forms[0].submit();
        else
          return document.getElementById(id).submit();
      }
      Vue.prototype.openDrawer = function(id){
        var drawerElement = this.$root.$children.find((child)=>{
          return child.$el.id==id;
        });
        drawerElement.open();
      }
    }
}
</script>