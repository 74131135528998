<template>
  <div style="width: 100%">
    <div style="width: 100%">
      <vue-user-role-select 
        :role-options="roleOptions" 
        :selected="rolesSelected"
        @select="handleSelect"
        @remove="handleRemove"
      ></vue-user-role-select>
    </div>
    <div>
      <template v-for="(roleSelected, index) of rolesSelected">
        <div :key="index">
          <input type="hidden" :value="roleSelected.id" :name="inputName(index, 'id')"/>
          <input type="hidden" :value="roleSelected.key" :name="inputName(index, 'role')"/>
          <input type="hidden" :value="roleSelected._destroy" :name="inputName(index, '_destroy')"/>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  components:{
    Multiselect,
  },
  props: {
    roleOptions: String,
    baseName: String,
    selected: {
      type: String,
      default: "[]",
    }
  },
  data: function(){
    return {
      options: [],
      rolesSelected: []
    }
  },
  methods: {
    inputName(index, attribute){
      return `${this.baseName}[${index}][${attribute}]`
    },
    handleSelect(role){
      let exists = this.rolesSelected.filter(item=>item.key == role.id).length>0
      if(!exists){
        this.rolesSelected.push(role)
      }
    },
    handleRemove(deleteRole){
      this.rolesSelected = this.rolesSelected.map(item => {
        if(!!item.id && item.key == deleteRole.key){
          item={...item, _destroy: 1}
        }
        return item
      }).filter(item => !!item.id || item.key != deleteRole.key)
    }
  },
  beforeMount(){
    this.options = JSON.parse(this.roleOptions)
    this.rolesSelected = JSON.parse(this.selected)
  }
}
</script>
