import axios from "axios";

export class CepAPI{
  constructor(){
    axios.defaults.headers = {'Content-Type': 'application/json'}
    this.http = axios;
  }

  getCEPData({cep=null, onResponse=null, onError=null}){
    if(cep.length==9){
      var _url = `https://viacep.com.br/ws/${cep}/json/`;
      cep = cep.split("-").join("")
      return this.http({
        url: _url,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json'
      })
        .then(onResponse)
        .catch(onError);
    }
  }
}