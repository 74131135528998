import axios from 'axios';

class ApiServiceClass{
    constructor(){
        //this.url = 'http://localhost:3000';
        //this.apiUrl = 'http://localhost:3000/api/v1';
    }

    getResume({onSucess=null, onError=null, data=null}){
        this._post({
            url: '/admin/order/attendances/resume',
            onSucess: onSucess,
            onError: onError,
            data: data
        });
    }

    _post({url=null, onSucess=null, onError=null, data=null}){
        var csrf_token = document.getElementsByName('csrf-token')[0].content;
        axios.post(
            url,
            data,
            { crossdomain: true, headers: { 'X-CSRF-Token': csrf_token} }
        ).then(onSucess).catch(onError);
    }
}

export const ApiService = new ApiServiceClass();
