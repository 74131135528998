const attendanceFormModule = {
    namespaced: true,
    state: () =>({ /* data */
        selectedExams : [],
        orderExams : [],
        documents: [],
        companyUnity: null,
        doctor: {
            data: {},
            errors: {},
        },
        patient: {
            data: {},
            errors: {}
        },
        materials: [],
    }),
    getters: {
        selectedExams: state => state.selectedExams,
        examsIds: state => state.examsIds,
        examsTotalPrice: state => {
            var _total = 0;
            state.selectedExams.forEach(exam => _total += !!exam.price ? parseFloat(exam.price) : 0);
            const formater = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2})
            return formater.format(_total);
        },
        examsTotalSellPrice: state => {
            var _total = 0;
            state.selectedExams.forEach(exam => _total += parseFloat(exam.sell_price));
            const formater = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2})
            return formater.format(_total);
        },
        examsMaxDeadline: state =>{
            var _max = 0;
            _max = Math.max(...state.selectedExams.map(selectedExam=>selectedExam.deadline))
            return _max;
        },
        hasSelectedExams: state => state.selectedExams.length > 0,
        hasDocuments: state => state.documents.length > 0,
        patient: state => state.patient.data,
        patientErrors: state => state.patient.errors,
        doctor: state => state.doctor.data,
        doctorErrors: state => state.doctor.errors,
        companyUnity: state => state.companyUnity
    },
    mutations : { /* methods */
        SET_SELECTED_EXAMS(state, selected) {
            let selectedExams = state.selectedExams
            if(!selectedExams.includes(selected)) 
                return state.selectedExams.push(selected)
        },
        REMOVE_SELECTED_EXAM(state, examId){
            state.selectedExams = [...state.selectedExams.filter(item=>item.id !== examId)];
        },

        SET_DOCUMENTS(state, documentsJson) {
            const documents = state.documents
            let found = false
            for(var i=0; i < documents.length; i++) {
                if(documents[i].exam_name == documentsJson.exam_name)
                    found = true
            }
            if(!found)
                documents.push(documentsJson)
            return state.documents = documents
        },

        SET_DOCTOR(state, data){
            state.doctor = {
                ...state.doctor,
                data: {
                    ...state.doctor.data,
                    ...data
                }
            }
        },
        SET_DOCTOR_ERRORS(state, data){
            state.doctor = {
                ...state.doctor,
                errors: {
                    ...state.doctor.errors,
                    ...data
                }
            }
        },
        SET_PATIENT(state, data){
            state.patient = {
                ...state.patient, 
                data: {
                    ...state.patient.data,
                    ...data
                }
            };
        },
        SET_PATIENT_ERRORS(state, data){
            state.patient = {
                ...state.patient, 
                errors: {
                    ...state.patient.errors,
                    ...data
                }
            };
        },
        SET_COMPANY_UNITY(state, data){
            state.companyUnity = {...data}
        },
        RESET_DOCUMENTS(state) {
            return state.documents = []
        },

        RESET_ORDER_EXAMS(state) {
            return state.orderExams = []
        },

        CLEAR_ALL (state) {
            return state.selectedExams = []
        }
    },

    actions : { /* computed */
        setSelectedExams(context, selected) {
            context.commit('SET_SELECTED_EXAMS', selected)
        },
        removeSelectedExam(context, examId){
            context.commit('REMOVE_SELECTED_EXAM', examId)
        },
        setDocuments(context, data) {
            context.commit('SET_DOCUMENTS', data)
        },
        setDoctor(context, data){
            context.commit('SET_DOCTOR', data);
        },
        setDoctorErrors(context, data){
            context.commit('SET_DOCTOR_ERRORS', data);
        },
        setPatient(context, data){
            context.commit('SET_PATIENT', data);
        },
        setPatientErrors(context, data){
            context.commit('SET_PATIENT_ERRORS', data);
        },
        setCompanyUnity(context, data){
            context.commit('SET_COMPANY_UNITY', data);
        },
        resetDocuments(context) {
            context.commit('RESET_DOCUMENTS')
        },
        resetOrderExams(context) {
            context.commit('RESET_ORDER_EXAMS')
        },
        clearAll(context) {
            context.commit('CLEAR_ALL')
        }
    }
}

const sidebardModule = {
    namespaced: true,
    state: () =>({ /* data */
        activeItem: 0,
        collapsed: true
    }),
    mutations : { /* methods */
        INCREASE_ACTIVE_ITEM(state) {
            return state.activeItem += 1;
        },
        TOOGLE_COLAPSE(state){
            return state.collapsed = !state.collapsed;
        },
        NOT_COLLAPSED(state){
            return state.collapsed = false
        }
    },
    actions : { /* computed */
        increaseActiveItem(context) {
            context.commit('INCREASE_ACTIVE_ITEM');
        },
        toogleCollapse(context){
            context.commit('TOOGLE_COLAPSE');
        },
        notCollapsed(context){
            context.commit('NOT_COLLAPSED');
        }
    }
}


export const globalStore = {
    modules: {
        attendanceFormModule: attendanceFormModule,
        sidebardModule: sidebardModule
    }
}