<script>

export default {
  data(){
    return {
      headerData: '',
      bodyData: '',
      footerData: '',
      editorConfig: {},
      advancedMode: false,
    }
  },
  props:{
    initialEditorData: {
      type: String,
      default: null
    },
  },
  methods: {
    onSystemVariableSelect(selectedOption, id){
      this.copyVariable(selectedOption.value);
    },
    copyVariable(text){
      this.copyTextToClipboard(`%{${text}}`);
      this.$toast.open({
        message: `Variável "${text}" copiada`,
        type: 'success',
        position: 'bottom-right',
        duration: 1500
      });
    }
  },
  beforeMount(){
    if(this.initialEditorData != null){
      var jsonData = JSON.parse(this.initialEditorData)
      this.headerData = jsonData.header;
      this.bodyData = jsonData.body;
      this.footerData = jsonData.footer;

      if(this.footerData || this.headerData )
        this.advancedMode = true;
    }
  }
}
</script>