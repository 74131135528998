import Vue from 'vue/dist/vue.esm';

// LOADING GLOBAL COMPONENTS




	
	
	import componentSidebarItem from "/var/www/myapp/app/webpacker/javascripts/components/global/sidebarItem.vue";
	Vue.component("vue-sidebar-item", componentSidebarItem);

	
	
	import componentMyDatepicker from "/var/www/myapp/app/webpacker/javascripts/components/global/myDatepicker.vue";
	Vue.component("vue-my-datepicker", componentMyDatepicker);

	
	
	import componentAlert from "/var/www/myapp/app/webpacker/javascripts/components/global/alert.vue";
	Vue.component("vue-alert", componentAlert);

	
	
	import componentDisabledBelongsTo from "/var/www/myapp/app/webpacker/javascripts/components/global/disabledBelongsTo.vue";
	Vue.component("vue-disabled-belongs-to", componentDisabledBelongsTo);

	
	
	import componentWizard from "/var/www/myapp/app/webpacker/javascripts/components/global/wizard.vue";
	Vue.component("vue-wizard", componentWizard);

	
	
	import componentMyMoney from "/var/www/myapp/app/webpacker/javascripts/components/global/myMoney.vue";
	Vue.component("vue-my-money", componentMyMoney);

	
	
	import componentWizardStep from "/var/www/myapp/app/webpacker/javascripts/components/global/wizardStep.vue";
	Vue.component("vue-wizard-step", componentWizardStep);

	
	
	import componentSidebar from "/var/www/myapp/app/webpacker/javascripts/components/global/sidebar.vue";
	Vue.component("vue-sidebar", componentSidebar);

	
	
	import componentFileInput from "/var/www/myapp/app/webpacker/javascripts/components/global/fileInput.vue";
	Vue.component("vue-file-input", componentFileInput);

	
	
	import componentSingleFileInput from "/var/www/myapp/app/webpacker/javascripts/components/global/singleFileInput.vue";
	Vue.component("vue-single-file-input", componentSingleFileInput);

	
	
	import componentDynamicForm from "/var/www/myapp/app/webpacker/javascripts/components/global/dynamicForm.vue";
	Vue.component("vue-dynamic-form", componentDynamicForm);

	
	
	import componentModal from "/var/www/myapp/app/webpacker/javascripts/components/global/modal.vue";
	Vue.component("vue-modal", componentModal);

	
	
	import componentMySelect from "/var/www/myapp/app/webpacker/javascripts/components/global/mySelect.vue";
	Vue.component("vue-my-select", componentMySelect);

	
	
	import componentAccordion from "/var/www/myapp/app/webpacker/javascripts/components/global/accordion.vue";
	Vue.component("vue-accordion", componentAccordion);

	
	
	import componentDrawer from "/var/www/myapp/app/webpacker/javascripts/components/global/drawer.vue";
	Vue.component("vue-drawer", componentDrawer);

	
	
	import componentMyCkEditor from "/var/www/myapp/app/webpacker/javascripts/components/global/myCkEditor.vue";
	Vue.component("vue-my-ck-editor", componentMyCkEditor);






	
	
	import componentDocuments from "/var/www/myapp/app/webpacker/javascripts/components/attendance/documents.vue";
	Vue.component("vue-documents", componentDocuments);

	
	
	import componentPatient from "/var/www/myapp/app/webpacker/javascripts/components/attendance/patient.vue";
	Vue.component("vue-patient", componentPatient);

	
	
	import componentExamsLaboratories from "/var/www/myapp/app/webpacker/javascripts/components/attendance/examsLaboratories.vue";
	Vue.component("vue-exams-laboratories", componentExamsLaboratories);

	
	
	import componentAttendanceForm from "/var/www/myapp/app/webpacker/javascripts/components/attendance/attendanceForm.vue";
	Vue.component("vue-attendance-form", componentAttendanceForm);

	
	
	import componentSampleDates from "/var/www/myapp/app/webpacker/javascripts/components/attendance/sampleDates.vue";
	Vue.component("vue-sample-dates", componentSampleDates);

	
	
	import componentDocumentsUpload from "/var/www/myapp/app/webpacker/javascripts/components/attendance/documentsUpload.vue";
	Vue.component("vue-documents-upload", componentDocumentsUpload);

	
	
	import componentSampleQuantity from "/var/www/myapp/app/webpacker/javascripts/components/attendance/sampleQuantity.vue";
	Vue.component("vue-sample-quantity", componentSampleQuantity);

	
	
	import componentDoctor from "/var/www/myapp/app/webpacker/javascripts/components/attendance/doctor.vue";
	Vue.component("vue-doctor", componentDoctor);

	
	
	import componentMaterials from "/var/www/myapp/app/webpacker/javascripts/components/attendance/materials.vue";
	Vue.component("vue-materials", componentMaterials);

	
	
	import componentIndexFilters from "/var/www/myapp/app/webpacker/javascripts/components/attendance/indexFilters.vue";
	Vue.component("vue-index-filters", componentIndexFilters);

	
	
	import componentSelectExamsLaboratories from "/var/www/myapp/app/webpacker/javascripts/components/attendance/selects/selectExamsLaboratories.vue";
	Vue.component("vue-selects-select-exams-laboratories", componentSelectExamsLaboratories);

	
	
	import componentSelectMaterials from "/var/www/myapp/app/webpacker/javascripts/components/attendance/selects/selectMaterials.vue";
	Vue.component("vue-selects-select-materials", componentSelectMaterials);

	
	
	import componentSelectCompanyUnity from "/var/www/myapp/app/webpacker/javascripts/components/attendance/selects/selectCompanyUnity.vue";
	Vue.component("vue-selects-select-company-unity", componentSelectCompanyUnity);

	
	
	import componentSelectAttendanceExams from "/var/www/myapp/app/webpacker/javascripts/components/attendance/selects/selectAttendanceExams.vue";
	Vue.component("vue-selects-select-attendance-exams", componentSelectAttendanceExams);

	
	
	import componentSelectRecipients from "/var/www/myapp/app/webpacker/javascripts/components/attendance/selects/selectRecipients.vue";
	Vue.component("vue-selects-select-recipients", componentSelectRecipients);






	
	
	import componentSelectExams from "/var/www/myapp/app/webpacker/javascripts/components/exam/selectExams.vue";
	Vue.component("vue-select-exams", componentSelectExams);






	
	
	import componentPriceTableExams from "/var/www/myapp/app/webpacker/javascripts/components/price_table/priceTableExams.vue";
	Vue.component("vue-price-table-exams", componentPriceTableExams);

	
	
	import componentDocumentTypes from "/var/www/myapp/app/webpacker/javascripts/components/price_table/documentTypes.vue";
	Vue.component("vue-document-types", componentDocumentTypes);






	
	
	import componentClinicForm from "/var/www/myapp/app/webpacker/javascripts/components/clinic/clinicForm.vue";
	Vue.component("vue-clinic-form", componentClinicForm);

	
	
	import componentClinicUnityForm from "/var/www/myapp/app/webpacker/javascripts/components/clinic/clinicUnityForm.vue";
	Vue.component("vue-clinic-unity-form", componentClinicUnityForm);






	
	
	import componentSelectRequiredDocumentType from "/var/www/myapp/app/webpacker/javascripts/components/documenttype/selectRequiredDocumentType.vue";
	Vue.component("vue-select-required-document-type", componentSelectRequiredDocumentType);






	
	
	import componentLaboratoryUnityForm from "/var/www/myapp/app/webpacker/javascripts/components/laboratory/laboratoryUnityForm.vue";
	Vue.component("vue-laboratory-unity-form", componentLaboratoryUnityForm);

	
	
	import componentLaboratoryForm from "/var/www/myapp/app/webpacker/javascripts/components/laboratory/laboratoryForm.vue";
	Vue.component("vue-laboratory-form", componentLaboratoryForm);






	
	
	import componentUserRoleSelect from "/var/www/myapp/app/webpacker/javascripts/components/user/userRoleSelect.vue";
	Vue.component("vue-user-role-select", componentUserRoleSelect);

	
	
	import componentUserRoleManager from "/var/www/myapp/app/webpacker/javascripts/components/user/userRoleManager.vue";
	Vue.component("vue-user-role-manager", componentUserRoleManager);






	
	
	import componentReportLayoutForm from "/var/www/myapp/app/webpacker/javascripts/components/report_layout/reportLayoutForm.vue";
	Vue.component("vue-report-layout-form", componentReportLayoutForm);











	
	
	import componentSelectExamNewCollect from "/var/www/myapp/app/webpacker/javascripts/components/new_collect/selectExamNewCollect.vue";
	Vue.component("vue-select-exam-new-collect", componentSelectExamNewCollect);


// LOADING PAGE SPECIFIC COMPONENTS
	// LOADING SELECTS
	import componentSelectLaboratory from './select/laboratory.vue';
	Vue.component("vue-select-laboratory", componentSelectLaboratory);
