<template>
  <div>
    <label class="typo__label" for="ajax">Código ou nome do exame*</label>
    <multiselect 
      v-model="localSelectedExams"
      id="ajax" 
      label="autocomplete_name" 
      track-by="id"
      placeholder="Digite para pesquisar" 
      open-direction="bottom" 
      :options="exams"
      :multiple="true"
      :searchable="true"
      :loading="isLoading" 
      :internal-search="false" 
      :close-on-select="true" 
      :options-limit="300" 
      :limit="10" 
      :max-height="600" 
      :show-no-results="false" 
      @search-change="asyncFind"
      @select="selectHandle"
      @remove="removeHandle"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <span class="multiselect__tag">
          <span>{{option.code}}</span> 
          <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
        </span>
      </template>
      <template slot="clear" slot-scope="props">
        <div class="multiselect__clear" v-if="hasSelectedExams" @mousedown.prevent.stop="clearAllData(props.search)"></div>
      </template>
      <span slot="noResult">Nenhum exame a mostrar.</span>
      <span slot="noOptions">Nenhum exame encontrado com o termo especificado</span>
    </multiselect>
    <div v-for="(item, index) in localSelectedExams" :key="index">
      <input type="hidden" v-bind:value="item.deadline" v-bind:name="`order_attendance[requested_exams_attributes][${index}][deadline]`"/>
      <input type="hidden" v-bind:value="item.exam_id" v-bind:name="`order_attendance[requested_exams_attributes][${index}][exam_id]`"/>
      <input type="hidden" v-bind:value="item.id" v-bind:name="`order_attendance[requested_exams_attributes][${index}][exam_laboratory_id]`"/>
      <input type="hidden" v-bind:value="item.price" v-bind:name="`order_attendance[requested_exams_attributes][${index}][price]`"/>
      <input type="hidden" v-bind:value="item.laboratory_id" v-bind:name="`order_attendance[requested_exams_attributes][${index}][laboratory_id]`"/>
      <input type="hidden" v-bind:value="item.code" v-bind:name="`order_attendance[requested_exams_attributes][${index}][exam_code]`"/>
      <input type="hidden" v-bind:value="item.name" v-bind:name="`order_attendance[requested_exams_attributes][${index}][exam_name]`"/>
      <input type="hidden" v-bind:value="item.external_exam_name" v-bind:name="`order_attendance[requested_exams_attributes][${index}][external_exam_name]`"/>
      <input type="hidden" v-bind:value="item.laboratory_name" v-bind:name="`order_attendance[requested_exams_attributes][${index}][laboratory_name]`"/>
    </div>
  </div>
  
</template>

<script>

  import Multiselect from 'vue-multiselect'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { ExamsApi } from '../http/examsApi'
  import { GlobalAutocomplete } from '../../http/autocomplete'

  const examsApi = new ExamsApi()
  const globalAutocomplete = new GlobalAutocomplete()
  const storeModuleName = 'attendanceFormModule';

  export default {
    components: {
        Multiselect
    },
    props: {
      selected : {
        type: Array,
        default: () => []
      },
      initialOptions: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        exams : [],
        isLoading: false
      }
    },
    created() {
      for(var i=0; i < this.selected.length; i++) {
        this.setSelectedExams(this.selected[i]);
      }
    },
    computed: {
        localSelectedExams: {
          get(){
            return this.selectedExams;
          },
          set(value){
            value.forEach(e=>this.setSelectedExams(e));
          }
        },
      ...mapState(storeModuleName, ['selectedExams','examsIds','documents']),
      ...mapGetters(storeModuleName, ['hasSelectedExams'])
    },
    methods : {
      asyncFind (query) {
        if(query.length > 3 && this.isLoading == false) {
          this.isLoading = true
          setTimeout(() => {
            globalAutocomplete.autocomplete('exam_laboratories', query).then(response => {
              this.exams = response.data.exams
              this.isLoading = false
            })
          }, 2000);
        }else if(query.length<=3){
          this.exams = this.initialOptions;
        }
      },

      clearAllData () {
        this.clearAll();
        this.resetDocuments();
        this.resetOrderExams();
      },

      getDeliveryDate(deadline) {
        let date = new Date();
        date.setDate(date.getDate() + deadline)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let fullDay = day.toString().length == 1 ? '0'+day : day
        let fullMonth = month.toString().length == 1 ? '0'+month : month
        return year + "-" + fullMonth + "-" + fullDay
      },

      getRequiredDocuments (examLaboratoryId) {
        examsApi.getRequiredDocuments(examLaboratoryId).then(response => {
          if(response.data)
            this.setDocuments(response.data);
        })
      },

      selectHandle(selected) {
        this.getRequiredDocuments(selected.id);
        this.exams = [];
      },

      removeHandle(selected) {
        this.removeSelectedExam(selected.id)
      },
      ...mapActions(storeModuleName, ['setSelectedExams', 'resetDocuments', 'resetOrderExams', 'setDocuments', 'removeSelectedExam'])
    },
    watch : {
      selectedExams : function(newState, _) {
        const _state = newState
        this.resetDocuments()
        this.resetOrderExams()
        for(var i=0;i <= _state.length; i++) {
          if(_state[i]) {
            this.getRequiredDocuments(_state[i].id)
          }
        }
      }
    },
    beforeMount(){
      this.exams = this.initialOptions;
    }
  }
</script>